import queryString from 'querystring'
import { TYPE_API } from "..";


export const listHotline = (
  queryParram
    : {
      page?: number, perPage?: number, type?: "" | "subscribe" | "mine",
      [`filter[status]`]?: string,
      [`filter[game_id]`]?: string,
      [`filter[category_type_id]`]?: string,
      [`filter[category_id]`]?: string,
      [`filter[search]`]?: string,
      ["filter[created_at_before]"]?: string,
      ["filter[created_at_after]"]?: string,
      ["filter[feedback_date_before]"]?: string,
      ["filter[feedback_date_after]"]?: string,
      ["filter[group_id]"]?: string,
      ["filter[last_activity_complete_causer_id]"]?: string
      'sort'?: 'feedback_date'
    }
): any => {
  return {
    method: 'GET',
    endpoint: `/admin/hotline?${queryString.stringify({ ...queryParram, page: queryParram?.page || 1, perPage: queryParram?.perPage || 10 })}`,
    type: TYPE_API.CRM,
  }
};


export const createPhone = (payload: any): any => {
  return {
    method: 'POST',
    endpoint: `/admin/hotline`,
    type: TYPE_API.CRM,
    body: payload
  }
};


export const getHotline = (
  { code }: any
): any => {
  return {
    method: 'GET',
    endpoint: `/admin/hotline/${code}`,
    type: TYPE_API.CRM,
  }
};


export const getInternalCommentHotline = (
  { code, page, perPage }: { code: string, page: number, perPage?: number }
): any => {
  return {
    method: 'GET',
    endpoint: `/admin/hotline/${code}/internal/comment?${queryString.stringify({ page: page || 1, perPage: perPage || 10 })}`,
    type: TYPE_API.CRM,
  }
};


export const commentInternalHotline = (
  formData: { code: string, body: string, files?: Array<any> }
): any => {
  return {
    method: 'POST',
    endpoint: `/admin/hotline/${formData?.code}/internal/comment`,
    type: TYPE_API.CRM,
    body: formData
  }
};

export const getActivitiesHotline = (
  { code, page, perPage }: { code: string, page?: number, perPage?: number }
): any => {
  return {
    method: 'GET',
    endpoint: `/admin/hotline/${code}/activities?${queryString.stringify({
      page: page || 1, perPage: perPage || 10,
    })}`,
    type: TYPE_API.CRM,
  }
};

export const receiveHotline = (
  { code, description }: { code: string, description?: any }
): any => {
  return {
    method: 'PUT',
    endpoint: `/admin/hotline/${code}/receive`,
    type: TYPE_API.CRM,
    body: description
  }
};

export const completeHotline = (
  { code, description }: { code: string, description?: any }
): any => {
  return {
    method: 'PUT',
    endpoint: `/admin/hotline/${code}/complete`,
    type: TYPE_API.CRM,
    body: description
  }
};

export const listCustomer = (
  { page, perPage, search }: any
): any => {
  return {
    method: 'GET',
    endpoint: `/admin/customer?${queryString.stringify({ page: page || 1, perPage: perPage || 10, [`filter[search]`]: search })}`,
    type: TYPE_API.CRM,
  }
};


export const rejectHotline = (
  formData: { code: string, description: any }
): any => {
  return {
    method: 'PUT',
    endpoint: `/admin/hotline/${formData?.code}/cancel`,
    type: TYPE_API.CRM,
    body: formData
  }
};


export const changeAdminHotline = (
  formData: { code: string, id: number }
): any => {
  return {
    method: 'PUT',
    endpoint: `/admin/hotline/${formData?.code}/change-admin`,
    type: TYPE_API.CRM,
    body: formData
  }
};


export const getRequirementsHotline = (
  { code, page, perPage }: { code: string, page?: number, perPage?: number }
): any => {
  return {
    method: 'GET',
    endpoint: `/admin/hotline/${code}/relate?${queryString.stringify({
      page: page || 1, perPage: perPage || 10,
    })}`,
    type: TYPE_API.CRM,
  }
};

export const getListGroupHotline = (
  { code }: {
    code: any,
  }
): any => {
  return {
    method: 'GET',
    endpoint: `/admin/hotline/${code}/group`,
    type: TYPE_API.CRM,
    // body: payload
  }
};

export const askForHelpGroupHotline = (formData: { body: any, code: any }): any => {
  return {
    method: 'PUT',
    endpoint: `/admin/hotline/${formData?.code}/add-group`,
    type: TYPE_API.CRM,
    body: formData?.body
  }
};


export const listConversationHotline = ({ code }: { code: any }): any => {
  return {
    method: 'GET',
    endpoint: `/admin/hotline/${code}/conversation`,
    type: TYPE_API.CRM
  }
}

export const actionEditHotlineTag = (formData: { body: any, code: string, idTag: any }): any => {
  return {
    method: 'PATCH',
    endpoint: `/admin/hotline/${formData?.code}/conversation/${formData?.idTag}`,
    type: TYPE_API.CRM,
    body: formData?.body
  }
}
