import styled from 'styled-components';

export default styled.div`
  .message-list {
    overflow: scroll;
    height : 58vh;
  };

  .message-list::-webkit-scrollbar {
    width: 0em;
  }
  .message-list::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3);
  }

  .message-item {
    color : blue;
    text-decoration: underline;
  }

  .ant-upload-list-item-container {
    max-width: 100px;
    padding-bottom: 10px;
    padding-right: 5px
  }

  .rce-mbox-photo--img {
    height: 10px
  }

  .rce-container-input inputContainer {
    padding-bottom: 16px;
    align-items: flex-start !important;
  }

  .title_ticket {
    font-size: 26px 
  }

  @media (max-width: 1350px) {
    .ant-card-head-wrapper {
      display: flex;
      flex-direction: column;
    }
  }


  @media (max-width: 400px) {
    .action_ticket {
      display: flex;
      flex-wrap: wrap;
    }

    .title_ticket {
      font-size:  16px
    }

    .ant-card-head-wrapper {
      display: flex;
      flex-direction: column;
    }
  }


`