/**
 *
 * TicketDetailPage
 *
 */
import { PlayCircleOutlined } from '@ant-design/icons';
import {
  Button, Descriptions,
  Empty, Input, Popconfirm, Row,
  Select, Space, Tag,
  Typography,
  message,
  Table,
  Col,
  Tabs
} from "antd";
import Card from "antd/es/card/Card";
import { Content } from "antd/es/layout/layout";
import { commentTicket, completeTicket, getCommentTicket, getTicket, receiveTicket, getActivitiesTicket } from "app/api/actions/ticket";
import 'react-chat-elements/dist/main.css';
import LoadingIndicator from "app/components/LoadingIndicator";
import { selectGlobalSlice } from 'app/slice/selectors';
import React, { memo, useCallback, useEffect, useMemo, useState, useRef } from "react";
import { MessageList } from "react-chat-elements";
import { useMutation, useParameterizedQuery, useQuery } from "react-fetching-library";
import { UploadOutlined } from '@ant-design/icons';

import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { showAlert } from "utils/helper";

import Upload, { UploadProps } from 'antd/es/upload/Upload';
import { RcFile } from 'antd/es/upload';
import { uploadImage } from 'app/api/actions/utilities';
import TabPane from 'antd/es/tabs/TabPane';
import { IDataTabs } from 'types';
import { getActivitiesHotline } from 'app/api/actions/hotline';
const { Text, Link } = Typography;
interface Props { }
const { Option } = Select

interface TicketDetailChatProps {
  code: string;
  loading: boolean;
}

export const TicketDetailLogs = memo((props: TicketDetailChatProps) => {

  const { query: queryActivityTicket, loading, payload: payloadActivitiesTicket } = useParameterizedQuery(getActivitiesHotline);

  useMemo(() => {
    if (!!props?.loading) {
      queryActivityTicket({ code: props.code });
    }
  }, [props?.loading])


  useEffect(() => {
    queryActivityTicket({ code: props.code });
  }, []);

  console.log('getActivitiesHotline___', payloadActivitiesTicket);



  const columns = useMemo(() => {
    return [
      {
        title: 'Hành động',
        dataIndex: 'event',
        key: 'event',
        align: 'center',
        fixed: 'left',
        // render: (__) => <Link to={`/ticket/${__}`}>{__}</Link>
      },
      {
        title: 'Thời gian',
        dataIndex: 'created_at',
        key: 'created_at',
        // fixed: 'left',
        // width: 50,
        align: 'center',
        // render: (__, _record, _idx) => _idx + 1
      },
      {
        title: 'Trạng thái',
        dataIndex: 'status_name',
        key: 'status_name',
        align: 'center',
        // fixed: 'left',
        // render: (__) => <Link to={`/ticket/${__}`}>{__}</Link>
      },

      {
        title: 'Nhân viên',
        dataIndex: 'causer_by',
        key: 'causer_by',
        align: 'center',
        // fixed: 'left',
        render: (__) => <Text>{__?.username}</Text>
      },
      {
        title: 'Chi tiết',
        dataIndex: 'description',
        key: 'description',
        align: 'center',
        // fixed: 'left',
        // render: (__) => <Link to={`/ticket/${__}`}>{__}</Link>
      },
      {
        title: 'Người hỗ trợ',
        dataIndex: 'properties',
        key: 'properties',
        align: 'start',
        render: (__) => <div className="">
          {__?.map((name, id) => <Text>{name?.username}<br />
          </Text>)}
          {/* <Text>{__?.map((name) => name?.username)}</Text> */}
        </div>

        // fixed: 'left',
        // render: (__) => <Link to={`/ticket/${__}`}>{__}</Link>
      },


    ]
  }, []);



  return <Table
    rowKey={'id'}
    scroll={{ x: 800 }}
    size="large"
    dataSource={payloadActivitiesTicket?.data?.collection || []}
    columns={columns as any}
    loading={loading || props?.loading}
    // columns={COLS}
    pagination={{
      position: ["bottomRight"], current: payloadActivitiesTicket?.data?.paginations?.meta?.curent_page, pageSize: 10, total: payloadActivitiesTicket?.data?.paginations?.meta?.total,
      onChange: (page, pageSize) => {
        console.log('____', page, pageSize);
        queryActivityTicket({ code: props?.code, page: page, perPage: 10 })
      },
      showTotal: (total) => `Tổng số: ${total} bản ghi`
    }}
    bordered
  />

});



