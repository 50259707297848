import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    ArrowUpOutlined
} from '@ant-design/icons';
import { Layout, BackTop, Affix } from 'antd';
import React, { ReactNode, useMemo, useState } from 'react';
import BreadcrumbList from './Breadcrumb/index';
import LayoutWrapper from './Layout.styles';
import MenuLayout from './Menu/index';
import { LayoutProvider } from './LayoutContext';
import HeaderLayout from './Header';
import { useQuery } from 'react-fetching-library';
import { getRole } from 'app/api/actions/authen';
import { useGlobalSliceSlice } from 'app/slice';
import { useDispatch } from 'react-redux';


const { Header, Content, Footer, Sider } = Layout;

interface DashboardProps {
    children: ReactNode
}

const Dashboard = ({ children }: DashboardProps) => {
    const [collapsed, setCollapsed] = useState<boolean>(false);
    const { actions } = useGlobalSliceSlice();
    const dispatch = useDispatch();

    const { payload, loading } = useQuery(getRole());

    useMemo(
        () => {
            if (!payload?.data || payload?.data?.permissions?.length == 0) return;
            const permissionUser = payload?.data?.permissions?.map(_per => _per?.name);

            dispatch(actions.saveRole({
                roles: permissionUser
            }));
        }, [payload]
    );

    return (
        <LayoutProvider>
            <LayoutWrapper>
                <Layout hasSider>
                    <Sider
                        className={collapsed ? 'layout-sider-mobile' : 'layout-sider'}
                        // style={{ display: collapsed ? 'none' : 'block', zIndex: collapsed ? 0 : 1 }}
                        breakpoint="lg"
                        width={240}
                        onBreakpoint={broken => setCollapsed(broken)}
                        collapsed={collapsed}
                    >
                        <Affix offsetTop={0.1}>
                            <div className='logo-wrapper' style={{ background: '#fff', boxShadow: '0 1px 9px -3px rgb(0 0 0 / 10%)' }}>
                                <img
                                    className="logo"
                                    src={"https://upload.wikimedia.org/wikipedia/commons/thumb/9/94/Video-Game-Controller-Icon-D-Edit.svg/1200px-Video-Game-Controller-Icon-D-Edit.svg.png"}
                                />
                            </div>
                        </Affix>
                        <MenuLayout />
                    </Sider>
                    <Layout
                        className="site-layout"
                        style={{ marginLeft: collapsed ? 80 : 240 }}
                    >
                        <Header
                            className="site-layout-background site-layout-header"
                            style={{ position: 'fixed', zIndex: 1, width: '100%', boxShadow: '0 1px 9px -3px rgb(0 0 0 / 10%)' }}
                        >
                            {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                                className: 'trigger',
                                onClick: () => setCollapsed(!collapsed),
                            })}
                            <div style={{ display: 'inline-block', marginRight: collapsed ? 115 : 275 }}>
                                <HeaderLayout />
                            </div>
                        </Header>
                        <Content className='layout-content'>
                            <BreadcrumbList />
                            <div>
                                {children}
                            </div>
                        </Content>
                        {/* <Footer style={{ textAlign: 'center' }} /> */}
                    </Layout>
                </Layout>
                <BackTop>
                    <div className='back-top-wrapper'>
                        <ArrowUpOutlined className='back-top' />
                    </div>
                </BackTop>
            </LayoutWrapper>
        </LayoutProvider>
    );
};

export default Dashboard;