import {
    LogoutOutlined,
    SettingOutlined, ShopOutlined,
    MailOutlined
} from '@ant-design/icons';
import { Avatar, Dropdown, Menu, Spin, Badge } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HeaderLayoutWrapper, { MenuHeaderWrapper } from './Header.style';
import { selectGlobalSlice } from 'app/slice/selectors';
import { useGlobalSliceSlice } from 'app/slice';
import { useNavigate } from 'react-router';

const MenuHeaderDropdown = () => {
    const { actions } = useGlobalSliceSlice()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    return <MenuHeaderWrapper>
        <Menu className="menu-header" selectedKeys={[]}>
            {/* <Menu.Item key="settings" style={{ height: 50 }}
                onClick={() => navigate("/reset-password")}
            >
                <SettingOutlined style={{ position: 'relative', top: -3, marginRight: 15 }} />
                Đổi mật khẩu
            </Menu.Item> */}
            <Menu.Divider />
            <Menu.Item key="logout" style={{ color: 'red', height: 50 }}
                onClick={e => {
                    // dispatch(logout())
                    localStorage.removeItem('accessToken')
                    localStorage.removeItem('email')
                    dispatch(actions.saveToken({ token: "", email: "" }))
                }}
            >
                <LogoutOutlined style={{ position: 'relative', top: -3, marginRight: 15 }} />
                Đăng xuất
            </Menu.Item>
            <Menu.Item key="email" style={{ color: 'red', height: 50 }}
                onClick={e => {
                    navigate('/');
                }}
            >
                <MailOutlined style={{ position: 'relative', top: -3, marginRight: 15 }} />
                Thông báo Email
            </Menu.Item>
        </Menu>
    </MenuHeaderWrapper>
};

const HeaderLayout = () => {
    const info = useSelector(selectGlobalSlice)
    return (
        <HeaderLayoutWrapper>
            <Dropdown
                overlay={<MenuHeaderDropdown />}
                placement="bottomLeft"
                arrow
            >
                <div className='header-avatar-wrapper'>
                    <Badge status="success" dot={true} offset={[-18, 48]}>
                        <Avatar
                            className="header-avatar"
                            alt="avatar"
                            src={"https://upload.wikimedia.org/wikipedia/commons/thumb/9/94/Video-Game-Controller-Icon-D-Edit.svg/1200px-Video-Game-Controller-Icon-D-Edit.svg.png"}
                        />
                    </Badge>
                    <span>{info?.email}</span>
                </div>
            </Dropdown>
        </HeaderLayoutWrapper>
    );
};

export default HeaderLayout;