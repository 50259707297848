/**
 *
 * Asynchronously loads the component for NewsEventsPage
 *
 */

import { lazyLoad } from "utils/loadable";

export const NewsEventsPage = lazyLoad(
  () => import("./index"),
  (module) => module.NewsEventsPage
);
