import {
  SettingOutlined,
  TeamOutlined,
  BankOutlined,
  AliwangwangOutlined,
  AndroidOutlined,
  TagsOutlined,
  CommentOutlined,
  UserOutlined,
  LayoutOutlined,
  GroupOutlined,
  AreaChartOutlined,
  HighlightOutlined,
  EditOutlined,
  MessageOutlined,
  PieChartOutlined,
  OrderedListOutlined,
  PhoneOutlined
} from '@ant-design/icons';
import { Menu } from 'antd';
import { selectTotalUnread } from 'app/pages/ChatPage/slice/selectors';
import queryString from 'querystring';
import React, { useEffect, useMemo } from 'react';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { selectGlobalSlice } from 'app/slice/selectors';
import { useLoadmore } from 'utils/customhook';
import { listGame } from 'app/api/actions/category';
import { useQuery } from 'react-fetching-library';
import { menuGroup } from 'app/api/actions/group';

interface SubMenuProps {
  link: string;
  title: string;
  key?: string;
}

interface MenuDataProps {
  key: string;
  title: string;
  icon: any;
  link: string;
  children?: Array<SubMenuProps>;
}

const { SubMenu, Item } = Menu;

const MenuData: Array<MenuDataProps> = [
  {
    key: 'menu-6',
    title: 'Thống kê',
    link: '/statistical',
    icon: <AreaChartOutlined />
  },
  {
    key: 'menu-7',
    title: 'Báo cáo',
    link: '/',
    icon: <PieChartOutlined />,
    children: [
      {
        title: "Báo cáo Login/Logout",
        link: '/report/1',
        key: 'report-token'
      },
      {
        title: "Báo cáo Chuyển trạng thái",
        link: '/report/2',
        key: 'report-change-status'
      },
      {
        title: "Báo cáo Phân loại hội thoại",
        link: '/report/3',
        key: 'report-conversation'
      }
    ]
  },
  {
    key: 'ticket-index',
    title: 'Quản lý ticket',
    link: '/tickets',
    icon: <AliwangwangOutlined />,
    children: [
      {
        title: 'Tất cả',
        link: '/tickets',
        key: 'ticket-index'
      },
      {
        title: 'CSKH level 2',
        link: '/tickets-group',
        key: 'sub-menu-2'
      },
      {
        title: 'Trả kết quả cho KH',
        link: '/tickets-waiting',
        key: 'sub-menu-3'
      },
    ]
  },
  {
    // key: 'tag-index',
    key: 'menu-9',
    title: 'Hotline',
    link: '/hotline',
    icon: <PhoneOutlined />,
  },
  {
    key: 'game-index',
    title: 'Quản lý Game',
    link: '/games',
    icon: <AndroidOutlined />,
  },
  // {
  //   key: 'menu-2',
  //   title: 'Chat, Comment',
  //   link: '/chats',
  //   icon: <CommentOutlined />,
  // },
  {
    // key: 'tag-index',
    key: 'conversation-index',
    title: 'Phân loại hội thoại',
    link: '/conversation',
    icon: <EditOutlined />,
  },
  {
    // key: 'tag-index',
    key: 'chat-template-index',
    title: 'Phân loại tin nhắn',
    link: '/template',
    icon: <MessageOutlined />,
  },
  {
    key: 'group-index',
    title: 'Quản lý Group',
    link: '/group',
    icon: <TeamOutlined />,
  },
  {
    key: 'tag-index',
    title: 'Quản lý tag',
    link: '/tags',
    icon: <TagsOutlined />,
  },
  {
    key: 'menu-3',
    title: 'Danh mục',
    link: '/categories',
    icon: <GroupOutlined />,
    children: [
      {
        title: 'Loại hỗ trợ',
        link: '/categories',
        key: 'category-index'
      },
      {
        title: 'Loại ticket',
        link: '/category-types',
        key: 'category-type-index'
      },
      {
        title: 'Thuộc tính',
        link: '/category-properties',
        key: 'category-property-index'
      },
    ]
  },
  {
    key: 'menu-4',
    title: 'Quản lý tài khoản',
    link: '/users',
    icon: <UserOutlined />,
  },
  {
    key: 'menu-5',
    title: 'Tin tức, sự kiện',
    link: '/news-events-list',
    icon: <LayoutOutlined />,
  },
  {
    key: 'menu-8',
    title: 'Wiki',
    link: '/wiki',
    icon: <OrderedListOutlined />,
    children: [
      {
        title: 'Loại Wiki',
        link: '/wiki',
        key: 'wiki-index'
      },
      {
        title: 'Thông tin',
        link: '/wiki-info',
        key: 'wiki-info-index'
      },
    ]
  },
];

const MenuLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const totalUnread = useSelector(selectTotalUnread)
  const { roles } = useSelector(selectGlobalSlice);
  const { queryList: queryListGame, items: itemListGame, loadmore: loadmoreListGame, page: pageListGame } = useLoadmore(listGame)
  const { query: queryListGroup, payload: payloadListGroup } = useQuery(menuGroup())

  useEffect(() => {
    queryListGame({ page: 1 })
    queryListGroup()
  }, [])

  // console.log('roles____', roles);

  console.log('payloadListGroup___', payloadListGroup);


  const subMenuGame = useMemo(() => {
    if (itemListGame?.length == 0) return
    return itemListGame?.map((__: any) => ({
      title: __?.name || "",
      link: `/tickets-game/${__?.code}`,
      key: `tickets-game-${__?.id}`
    }))
  }, [itemListGame])

  const subMenuGroup = useMemo(() => {
    if (payloadListGroup?.data?.collection?.length == 0) return
    return payloadListGroup?.data?.collection?.map((__: any) => ({
      title: __?.name,
      link: `/tickets-group-id/${__?.id}`,
      key: `tickets-group-id-${__?.id}`
    }))
  }, [payloadListGroup])

  const subMenuReport = useMemo(() => {
    let data = [
      {
        "id": 1,
        "name": "Báo cáo Login/Logout",
        "key": 'report-token'
      },
      {
        "id": 2,
        "name": "Báo cáo Chuyển trạng thái",
        "key": 'report-change-status'
      },
      {
        "id": 3,
        "name": "Báo cáo Phân loại hội thoại",
        "key": 'report-conversation'
      }
    ]
    return data?.map((__: any) => ({
      title: __?.name,
      link: `/report/${__?.id}`,
      key: `report-${__?.id}`
    }))
  }, [])


  const params = queryString.parse(
    location.search.slice(1, location.search.length),
  );

  console.log({ roles })


  return (
    <Menu
      theme="light"
      mode="inline"
      // items={items}      
      selectedKeys={
        (!!params.type
          ? `${location.pathname as string}?type=${params.type as string}`
          : (location.pathname as string)) as any
      }
    // defaultOpenKeys={MenuData?.map(menu => menu.key)}
    >
      {MenuData
        ?.filter((menu: MenuDataProps) => {
          if (menu.key == 'menu-2') return true;
          if (menu.key == 'menu-3') {
            let checkRoles = menu?.children?.filter((__: any) => roles?.includes(__?.key))
            // return menu.children?.every(_child => {
            //   roles?.includes(_child.key)
            // })
            return checkRoles?.length != 0 ? true : false
          }

          if (menu.key == 'menu-4') {
            return (roles?.includes('permission-index') || roles?.includes('admin-index'))
          }
          if (menu.key == 'menu-5') {
            return (roles?.includes('news-index'))
          }
          if (menu.key == 'menu-6') {
            return roles?.includes('dashboard-admin') || roles?.includes('dashboard-auth')
          }
          if (menu.key == 'menu-9') {
            return roles?.includes('hotline-index')
          }
          if (menu.key == 'menu-7') {
            return roles?.includes('report-token') || roles?.includes('report-change-status') || roles?.includes('report-conversation')
          }
          if (menu.key == 'menu-8') {
            return roles?.includes('wiki-index') || roles?.includes('wiki-info-index')
          }
          // if (menu.key == 'menu-8') {
          //   let checkRoles = menu?.children?.filter((__: any) => roles?.includes(__?.key))
          //   console.log('checkRoles___', checkRoles);
          //   return (
          //     <SubMenu key={menu.key} icon={menu.icon} title={menu.title}>
          //       {checkRoles?.map((_subMenu: SubMenuProps) => {
          //         return (
          //           <Item
          //             key={_subMenu.link}
          //             onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
          //           >
          //             <Link to={_subMenu.link}>{_subMenu.title}</Link>
          //           </Item>
          //         )
          //       })}
          //     </SubMenu>
          //   )
          // }
          return roles?.includes(menu.key)
        })
        .map((menu: MenuDataProps) => {
          if (!menu.children) {
            let Icon = menu.icon
            return <Item
              key={menu.link}
              onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
              icon={<div style={{ position: 'relative', height: 40 }} >
                {Icon}
                {
                  menu.key == 'menu-2' && !!totalUnread && totalUnread > 0 && <div style={{
                    position: 'absolute', width: 10, height: 10,
                    backgroundColor: 'red', borderRadius: 10,
                    right: -5, top: 5
                  }} />
                }
              </div>}
            >
              <Link to={menu.link}>{menu.title}</Link>
            </Item>
          }
          if (menu.key == 'menu-7') {
            let checkRoles = menu?.children?.filter((__: any) => roles?.includes(__?.key))
            console.log('checkRoles___', checkRoles);

            return (
              <SubMenu key={menu.key} icon={menu.icon} title={menu.title}>
                {checkRoles?.map((_subMenu: SubMenuProps) => {
                  return (
                    <Item
                      key={_subMenu.link}
                      onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                    >
                      <Link to={_subMenu.link}>{_subMenu.title}</Link>
                    </Item>
                  )
                })}
              </SubMenu>
            )
          }
          if (menu.key == 'menu-8') {
            let checkRoles = menu?.children?.filter((__: any) => roles?.includes(__?.key))
            return (
              <SubMenu key={menu.key} icon={menu.icon} title={menu.title}>
                {checkRoles?.map((_subMenu: SubMenuProps) => {
                  return (
                    <Item
                      key={_subMenu.link}
                      onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                    >
                      <Link to={_subMenu.link}>{_subMenu.title}</Link>
                    </Item>
                  )
                })}
              </SubMenu>
            )
          }
          if (menu.children?.[0]?.key == 'ticket-index') {
            return (
              <SubMenu key={menu.key} icon={menu.icon} title={menu.title}>
                {menu.children?.filter((_subMenu: SubMenuProps) => {
                  if (_subMenu.key == 'sub-menu-2' || _subMenu.key == 'sub-menu-3') return true;
                  return roles?.includes(_subMenu.key)
                })?.concat(subMenuGroup || [])?.concat(subMenuGame || [])?.map((_subMenu: SubMenuProps) => (
                  <Item
                    key={_subMenu.link}
                    onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                  >
                    <Link to={_subMenu.link}>{_subMenu.title}</Link>
                  </Item>
                ))}
              </SubMenu>
            )
          }
          // if (menu?.key == 'menu-7') {
          //   return (
          //     <SubMenu key={menu.key} icon={menu.icon} title={menu.title}>
          //       {menu.children?.concat(subMenuReport || [])?.map((_subMenu: SubMenuProps) => (
          //         <Item
          //           key={_subMenu.link}
          //           onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
          //         >
          //           <Link to={_subMenu.link}>{_subMenu.title}</Link>
          //         </Item>
          //       ))}
          //     </SubMenu>
          //   )
          // }
          return (
            <SubMenu key={menu.key} icon={menu.icon} title={menu.title}>
              {menu.children?.map((_subMenu: SubMenuProps) => (
                <Item
                  key={_subMenu.link}
                  onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                >
                  <Link to={_subMenu.link}>{_subMenu.title}</Link>
                </Item>
              ))}
            </SubMenu>
          );
        })}
    </Menu>
  );
};

export default memo(MenuLayout);
