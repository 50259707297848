import { Button, Col, Form, Input, Modal, Row, Space, Spin, Typography } from 'antd';
import { forgotPassword } from 'app/api/actions/authen';
import { createRole, updateRole } from 'app/api/actions/roles';
import React, { memo, useEffect, useMemo } from 'react';
import { useMutation } from 'react-fetching-library';
import { showAlert } from 'utils/helper';

const { Text } = Typography;

const ModalResetPass = ({
    type,
    onHide,
}) => {
    const [form] = Form.useForm();

    const { loading: loading, mutate: mutateForgot } = useMutation(forgotPassword);

    // useEffect(
    //     () => {
    //         form.resetFields()
    //     }, []
    // );

    const resetModal = () => {
        onHide();
        form.resetFields();
    }

    return (
        <Modal
            open={type}
            style={{ top: 150 }}
            title={'Yêu cầu đặt lại mật khẩu'}
            onCancel={resetModal}
            footer={[
                <Space key={1} size={20}>
                    <Button
                        type="primary"
                        className="btn-base"
                        disabled={loading}
                        loading={loading}
                        onClick={async () => {
                            form.validateFields()
                                .then(async values => {
                                    console.log('values___', values);
                                    const { payload }: any = await mutateForgot({
                                        email: values?.name
                                    });

                                    resetModal();
                                    if (payload?.status === 'success') {
                                        showAlert.success(`${payload?.message || 'Thành công'}`)
                                    } else {
                                        showAlert.error(`${payload?.message || 'Thất bại'}`)
                                    }
                                })
                        }}
                    >
                        Xác nhận
                    </Button>
                    <Button
                        type="primary"
                        className="btn-base"
                        danger
                        onClick={resetModal}
                    >
                        Huỷ
                    </Button>
                </Space>,
            ]}
        >
            <Spin
                key={2}
                spinning={loading}
            >
                <Form
                    form={form}
                    name="basic"
                    style={{ marginTop: 20 }}
                    layout="vertical"
                    initialValues={{ remember: true }}
                >
                    <Row gutter={30}>
                        <Col span={24}>
                            <Form.Item
                                label={<Text>Email</Text>}
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        validator: (_, value) =>
                                            value
                                                ? Promise.resolve()
                                                : Promise.reject('Email không được để trống'),
                                    },
                                ]}
                            >
                                <Input
                                    placeholder="Email"
                                    allowClear
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </Modal>
    )
};

export default memo(ModalResetPass);