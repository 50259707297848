import { Button, Card, Table, Typography, Modal, Popconfirm, Tooltip, Space, Form, Spin, Row, Col, Input, Select } from "antd";
import { createTag, deleteTag, getTags } from "app/api/actions/tags";
import React, { Fragment, memo, useCallback, useEffect, useMemo, useState } from "react";
import { useMutation, useParameterizedQuery } from "react-fetching-library";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'querystring';
import { showAlert } from "utils/helper";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import moment from "moment";
import ModalUpdate from "./components/ModalUpdate";
import { useLoadmore } from "utils/customhook";
import { listGame } from "app/api/actions/category";
import { getgroups } from "process";
import { createGroup, deleteGroups, getGroup, getMultiUserGroup, getUserGroup } from "app/api/actions/group";

const { Text } = Typography;
const { confirm } = Modal;
const { Option } = Select;

const GroupPage = () => {
    const [form] = Form.useForm();
    const location = useLocation()
    const navigate = useNavigate()
    const [search, setSearch] = useState("")
    const [page, setPage] = useState<number>(1);
    const [confirmDelete, setConfirmDelete] = useState<any>(null);
    const [openCreate, setOpenCreate] = useState<boolean>(false);

    const { payload, loading, query } = useParameterizedQuery(getGroup);
    const { mutate, loading: deleting } = useMutation(deleteGroups)
    const { mutate: mutateCreateGroup, loading: loadingCreateGroup } = useMutation(createGroup);

    const { page: pageGame, loadmore, loadingList, queryList, totalPage, items, } = useLoadmore(listGame)
    const { payload: payloadUser, loading: loadingUser, query: queryUserGroup } = useParameterizedQuery(getMultiUserGroup)


    const [openUpdate, setOpenUpdate] = useState(false)
    const [selectedUpdate, setselectedUpdate] = useState(null)
    const [idTags, setIdTags] = useState(null)



    useEffect(() => {
        try {
            let obj: any = queryString.parse(location?.search.slice(1, location?.search.length))
            console.log("__obj__ ::", obj)
            setPage(parseInt(obj.page || 1))
        } catch (error) {
            setPage(1)
        }
    }, [location?.search]);

    const _fetchList = useCallback(({ page, filter }: { page: number, filter?: string }) => {
        query({ page, filter });
    }, []);

    useEffect(() => {
        _fetchList({ page, filter: "" })
        queryList({
            page: 1,
            perPage: 100
        })
    }, [])


    const columns = useMemo(() => {
        return [
            {
                title: '#',
                dataIndex: 'id',
                key: 'id',
                // width: 150,
                render: (__, _record, _idx) =>
                    <Text>
                        {_idx + 1}
                    </Text>
            },
            {
                title: 'Tên Group',
                dataIndex: 'name',
                key: 'name',
                render: (name) => {
                    return <Text style={{}}>{name}</Text>
                }
            },
            {
                title: 'Game',
                dataIndex: 'games',
                key: 'games',
                render: (games) => {
                    return <Space style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                        {games?.map((__) =>
                            <Text style={{ whiteSpace: 'break-spaces' }}>
                                {__?.name},
                            </Text>
                        )}
                    </Space>
                    // return <Space direction='vertical'>
                    //     <Text style={{}}>{games?.name}</Text>
                    // </Space>

                }
            },
            {
                title: 'Telegram Id',
                dataIndex: 'telegram_chat_id',
                key: 'telegram_chat_id',
                render: (tele) => {
                    return <Space direction='vertical'>
                        <Text style={{}}>{tele}</Text>
                    </Space>

                }
            },
            {
                title: 'Users',
                dataIndex: 'users',
                key: 'users',
                render: (_user) => {
                    return <Space style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                        {_user?.map((__) =>
                            <Text style={{ whiteSpace: 'break-spaces' }}>
                                {__?.name},
                            </Text>
                        )}
                    </Space>

                    // <Text style={{ whiteSpace: 'break-spaces' }}>{
                    //     _user?.map((__) => __?.name).join(` , \n`)
                    // }</Text>
                }
            },
            {
                title: 'Thời gian tạo',
                dataIndex: 'created_at',
                key: 'created_at',
                width: 300,
                render: (created_at) => {
                    return <Text>
                        {moment(created_at).format('HH:mm DD/MM/YYYY')}
                    </Text>
                }
            },
            {
                title: 'Thao tác',
                dataIndex: 'id',
                key: 'id',
                width: 100,
                render: (id, record: any) => {
                    // console.log('record___', record);
                    return <Space>
                        <Tooltip title="Chỉnh sửa">
                            <Button type="primary" icon={<EditOutlined />}
                                onClick={() => {
                                    setOpenUpdate(true)
                                    setselectedUpdate(record)
                                    setIdTags(record?.id)
                                    // setOpenUpdate(true)
                                }}
                            />
                        </Tooltip>
                        <Tooltip title="Xoá">
                            <Popconfirm
                                title="Xác nhận xoá"
                                description="Bạn chắc chắn muốn xoá group này?"
                                open={confirmDelete == id}
                                onConfirm={async () => {
                                    let { payload } = await mutate(Number(id))
                                    if (payload?.code == 200) {

                                        query({ page })
                                        setConfirmDelete(null)

                                        showAlert.success('Xoá group thành công.');
                                    } else {
                                        showAlert.error(payload?.message || "Xoá group không thành công.")
                                    }
                                }}
                                okButtonProps={{ loading: deleting }}
                                cancelButtonProps={{ disabled: deleting }}
                                onCancel={() => setConfirmDelete(null)}
                            >
                                <Button type="primary" danger icon={<DeleteOutlined />}
                                    disabled={deleting}
                                    onClick={() => {
                                        setConfirmDelete(id);
                                    }}
                                />
                            </Popconfirm>
                        </Tooltip>
                    </Space>
                }
            },
        ]
    }, [confirmDelete, deleting]);

    const resetModal = useCallback(
        () => {
            form.resetFields();
            setOpenCreate(false);
        }, []
    );

    const onFinish = useCallback(async (values: any) => {
        console.log('values___', values);
        let totalArrId = items || [];
        let listGameIdId = totalArrId?.filter((item: any, index: number, self: any) => index === self?.findIndex((_item: any) => _item?.id === item.id))
        console.log("listGameIdId", listGameIdId)
        // let listGameIdFinal = (listGameIdId || []).filter((_item) => values?.games?.includes(_item?.name))
        let listGameIdFinal = (listGameIdId || []).filter((_item) => values?.games == _item.id)

        let listUserId = Object?.entries(payloadUser?.data?.collection || {})?.map(([key, value]) => (value))?.filter((item: any, index: number, self: any) => index === self?.findIndex((_item: any) => _item?.id === item.id))
        let listUserIdFinal = (listUserId || []).filter((_item: any) => values?.user?.includes(_item?.id))
        const { payload }: any = await mutateCreateGroup({
            name: values?.name,
            games: values?.games.map((_game) => {
                return {
                    id: _game
                }
            }),
            // game_id: values?.games ? listGameIdFinal?.map((__) => {
            //     return {
            //         id: __?.id
            //     }
            // }) : [],
            telegram_chat_id: values?.telegram || null,
            users: values?.user ? listUserIdFinal?.map((__: any) => {
                return {
                    id: __?.id
                }
            }) : [],
        });

        resetModal();
        _fetchList({ page })
        setOpenCreate(false);
        if (payload?.status == 'success') {
            showAlert.success('Tạo group thành công')
        } else {
            showAlert.error('Tạo group thất bại')
        }
    }, [items, payloadUser]);

    return (
        <Fragment>
            <Helmet
                titleTemplate="Quản lý Group - CRM"
                defaultTitle="Quản lý Group - CRM"
            >
                <meta name="description" content="Quản lý Group - CRM" />
            </Helmet>

            <Modal
                open={openCreate}
                title="Tạo mới group"
                style={{ top: 150 }}
                onCancel={resetModal}
                footer={[

                ]}
            >
                <Spin spinning={loadingCreateGroup}>
                    <Form
                        form={form}
                        name="basic"
                        style={{ marginTop: 20 }}
                        layout="vertical"
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                    >
                        <Row gutter={30}>
                            <Col span={24}>
                                <Form.Item
                                    label={<Text>Tên group:</Text>}
                                    name="name"
                                    rules={[
                                        {
                                            required: true,
                                            validator: (_, value) =>
                                                value
                                                    ? Promise.resolve()
                                                    : Promise.reject('Tên tag không được để trống'),
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="Tên tag"
                                        allowClear
                                    />
                                </Form.Item>
                                <Form.Item label="Game"
                                    name='games'
                                    required
                                    rules={[{
                                        required: true,
                                        validator: (_, value) =>
                                            value
                                                ? Promise.resolve()
                                                : Promise.reject('Game không được để trống')
                                    }]}
                                >
                                    <Select
                                        placeholder="Chọn game"
                                        mode="multiple"
                                        onChange={(value) => {
                                            let queryString = value?.map(id => `game_ids[]=${id}`).join('&');
                                            setTimeout(() => {
                                                queryUserGroup({
                                                    // id: filerData?.filter((_item) => value?.includes(_item?.name))?.[0]?.id
                                                    listGame: queryString

                                                })
                                            }, 1000);
                                        }}
                                        onPopupScroll={(e) => !!loadmore && loadmore(e, { page: (pageGame || 0) + 1 })}
                                    >
                                        {items?.map((__, index) => {
                                            return (
                                                <Option value={__?.id} label={__?.name} key={index}
                                                >
                                                    <Space>
                                                        {__?.name}
                                                    </Space>
                                                </Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                                {!!payloadUser &&
                                    <Form.Item label="User"
                                        name='user'
                                        required
                                        rules={[{
                                            required: true,
                                            validator: (_, value) =>
                                                value
                                                    ? Promise.resolve()
                                                    : Promise.reject('User không được để trống')
                                        }]}
                                    >
                                        <Select
                                            loading={loadingUser}
                                            mode="multiple"
                                        // onPopupScroll={(e) => !!loadmore && loadmore(e, { page: (pageGame || 0) + 1 })}
                                        >
                                            {Object?.entries(payloadUser?.data?.collection || {})?.map(([key, value]) => (value))?.map((__: any, index: number) => {
                                                return (
                                                    <Option value={__?.id} label={__?.name} key={index}
                                                    >
                                                        <Space>
                                                            {__?.name}
                                                        </Space>
                                                    </Option>
                                                )
                                            })}
                                        </Select>
                                    </Form.Item>
                                }
                                <Form.Item label="Telegram Id"
                                    name='telegram'
                                >
                                    <Input type="number" allowClear max={1000} placeholder="telegram id " id="telegram" />
                                </Form.Item>
                                <Form.Item wrapperCol={{ span: 12, offset: 9 }}>
                                    <Space size={20}>
                                        <Button
                                            className="btn-base"
                                            disabled={loadingCreateGroup}
                                            loading={loadingCreateGroup}
                                            key="submit" type="primary" htmlType="submit"
                                        >
                                            Xác nhận
                                        </Button>
                                        <Button
                                            type="primary"
                                            className="btn-base"
                                            danger
                                            onClick={resetModal}
                                        >
                                            Huỷ
                                        </Button>
                                    </Space>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>

            <Card
                className="card"
                title="Quản lý Group"
                extra={[
                    <Button type="primary" onClick={() => setOpenCreate(true)}>
                        Thêm mới
                    </Button>
                ]}
            >
                {/* <Input.Search
                    placeholder="Tìm kiếm tên tag"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    onSearch={() => {
                        _fetchList({
                            page,
                            filter: search
                        })
                    }}
                    style={{ width: 320, marginBottom: 20 }}
                    size="large"
                /> */}
                <Table
                    dataSource={payload?.data?.collection || []}
                    columns={columns as any}
                    loading={loading}
                    rowKey={'id'}
                    pagination={{
                        position: ["bottomRight"], current: page, pageSize: 10, total: payload?.data?.paginations?.meta?.total,
                        onChange: (page, pageSize) => {
                            navigate("/group?page=" + page)
                            // _fetchList({ page, filter: search })
                            _fetchList({ page });
                        },
                        showTotal: (total) => `Tổng số: ${total} bản ghi`
                    }}
                />
                <ModalUpdate open={openUpdate} idTags={idTags} setOpen={(open) => {
                    setOpenUpdate(open)
                    // setIdUser(undefined)
                }} refreshWhenUpdated={() => _fetchList({ page, filter: search })} selected={selectedUpdate} />
            </Card>
        </Fragment>
    )
};

export default memo(GroupPage);