/**
 *
 * Asynchronously loads the component for FanPage
 *
 */

import { lazyLoad } from "utils/loadable";

export const FanPage = lazyLoad(
  () => import("./index"),
  (module) => module.FanPage
);
