/**
 *
 * TicketDetailPage
 *
 */
import { UploadOutlined } from '@ant-design/icons';
import {
  Button, Descriptions,
  Input,
  Row,
  Select, Space,
  Typography,
  message
} from "antd";
import { Content } from "antd/es/layout/layout";
import { commentInternalTicket, commentTicket, getActivitiesTicket, getCommentTicket, getInternalCommentTicket } from "app/api/actions/ticket";
import LoadingIndicator from "app/components/LoadingIndicator";
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import 'react-chat-elements/dist/main.css';
import { useMutation, useParameterizedQuery, useQuery } from "react-fetching-library";

import { showAlert } from "utils/helper";

import Upload, { UploadProps } from 'antd/es/upload/Upload';
import { uploadImage } from 'app/api/actions/utilities';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { selectGlobalSlice } from 'app/slice/selectors';
import { listSelectMess } from 'app/api/actions/chatting';
import { commentInternalHotline, getInternalCommentHotline } from 'app/api/actions/hotline';
const { Text, Link } = Typography;
interface Props { }
const { Option } = Select

interface TicketInternalChatProps {
  id: string;
  data?: any;
  code?: any;
}

const __props: UploadProps = {
  listType: 'picture',
  name: 'file',
};

export const TicketInternalChat = memo((props: TicketInternalChatProps) => {

  const { mutate, loading: loadingSentCmt, payload } = useMutation(commentInternalHotline)
  const { payload: _payload, query: _query } = useParameterizedQuery(getInternalCommentHotline)
  const { mutate: _mutateUpload, loading: uploading } = useMutation(uploadImage)
  // const { query: queryActivityTicket, payload: payloadActivitiesTicket } = useQuery(getActivitiesTicket({ code: props?.code }))

  const [loadingSentFile, setloadingSentFile] = useState(false)
  const [value, setValue] = useState("")
  const { payload: payloadListMess, query: queryListMess } = useQuery(listSelectMess())

  const messagesEndRef = useRef<any>();
  const info = useSelector(selectGlobalSlice)
  const [fileUrl, setFileUrl] = useState<any>([])

  //life
  useEffect(() => {
    if (!props?.code) return
    _query({
      code: props?.code,
      page: 1,
      perPage: 10000
    })
    // queryActivityTicket()
  }, [props?.code])

  // useMemo(() => {
  //   if (!!props?.callback) props?.callback(value)
  // }, [value])


  const disableButtonSent = useMemo(() => {
    return !value
  }, [value])


  //action
  const scrollToBottom = () => {
    messagesEndRef?.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom()
  }, [_payload?.data?.collection])

  console.log('payloadpayload__comment', _payload);

  const _sent = useCallback(async (type: "text" | "file", value: string) => {
    if (!value) {
      return
    }
    const { payload } = await mutate({
      code: props?.code,
      // body: type == "text" ? value : "files",
      // ...(type == "file" ? { files: [value] } : {})
      body: type == "text" ? value : value, ...({ files: fileUrl })
    })


    setloadingSentFile(false)
    if (payload?.code == 200) {
      setFileUrl([])
      setValue("")
      _query({
        code: !!props && props?.code,
        page: 1,
        perPage: 1000
      })
      return
    }
    showAlert.error(`${payload?.message || 'Gửi tin nhắn thất bại'}`);
  }, [props, value, fileUrl])

  return <Content
    style={{
      minHeight: 280,
      background: 'white',
      borderRadius: 4,
      padding: 8,
      alignItems: 'center',
      justifyContent: 'center',
      // display: 'flex',
      width: '100%'
    }}
  >
    <Row style={{}} >
      <Descriptions size='small' title="Phản hồi nội bộ hotline" bordered
        style={{ width: '100%' }}
        column={2}
      >
        <Descriptions.Item span={3} style={{}}>
          <div className='message-list'>
            {!!_payload && _payload?.data?.collection?.sort((a, b) => a.id - b.id)?.map((__, id) => {
              return (
                <div key={id} style={{ display: 'flex', flexDirection: 'column' }}>
                  <div style={{
                    // padding: 8,
                    backgroundColor: "white",
                    display: 'flex', flexDirection: 'row', justifyContent: __?.causer_id === info?.user_id ? "flex-end" : "flex-start"
                  }}>
                    <div style={{
                      boxShadow: 'rgb(0 0 0 / 25%) 0px 1px 4px', marginLeft: 4,
                      maxWidth: '70%', backgroundColor: __?.causer_id != info?.user_id ? 'white' : "#BCEAFF", padding: 8, borderRadius: 8, display: 'flex', flexDirection: 'column'
                    }}>
                      {__?.files?.length > 0 ?

                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          {__?.files
                            ?.map((_file, index) => {
                              return (
                                <div key={index} className="" style={{ display: 'flex', flexDirection: 'column' }}>
                                  <Text onClick={() => {
                                    window.open((_file as any)?.file_url, "_blank")
                                  }} style={{ marginTop: 2, textDecoration: 'underline', color: 'blue' }}>{
                                      _file?.file_name
                                      // "hihi"
                                    }</Text>
                                  {/* <Text style={{ textAlign: __?.causer_id === info?.user_id ? 'right' : "left" }}>{__?.body}</Text> */}
                                </div>
                              )
                            })}
                          <Text style={{ textAlign: __?.created_by?.type === "admin" ? 'right' : "left" }}>{__?.body}</Text>
                        </div>
                        :
                        <Text style={{ textAlign: __?.causer_id === info?.user_id ? 'right' : "left" }}><Text style={{ color: '#3f96fe' }}>{__?.created_by?.username}</Text><br />{__?.body}</Text>
                      }
                    </div>
                  </div>
                  <Text style={{ textAlign: __?.causer_id === info?.user_id ? 'right' : "left", color: "gray", marginBottom: 8, fontSize: 12, marginTop: 4, }}>{moment(__?.created_at).format('DD/MM/YYYY HH:mm')}</Text>
                  <div ref={messagesEndRef} />
                </div>
              )
            })}
          </div>
          {/* <MessageList
              referance={null}
              ref={messageListRef}
              className='message-list'
              lockable={true}
              dataSource={_data}
              onClick={(item) => {
                if (!!(item as any)?.fileUrl) {
                  window.open((item as any)?.fileUrl, "_blank")
                }

              }}
            /> */}
        </Descriptions.Item>
        <Descriptions.Item span={3}  >
          <Input.TextArea placeholder="Nhập nội dung giao tiếp" onChange={(e) => setValue(e.target.value)} value={value} style={{ minHeight: 100 }} />
          <Space style={{ justifyContent: 'space-between', marginTop: 8, width: '100%', alignItems: 'flex-start' }} >
            {/* <div></div> */}
            {/* <Button type="primary" size="large" onClick={_sentFile}>File</Button> */}

            <Upload {...__props}
              showUploadList={payload?.code == 200 ? false : true}
              onChange={(info) => {
                let checkUpload = info?.fileList?.some((__) => __?.status === 'uploading')
                let checkError = info?.fileList?.some((__) => __?.status === 'error')
                if (checkUpload) {
                  setloadingSentFile(true);
                  return;
                }
                if (checkError) {
                  message.error('Gặp lỗi, xin vui lòng thử lại');
                  return
                }
                setFileUrl(info?.fileList?.map((__) =>
                  __?.response?.data?.file_name
                ))
                // _sent("file", info?.file?.response?.data?.file_name)
                setloadingSentFile(false)
                if (info?.file?.status === "removed") {
                  setFileUrl(undefined)
                }
              }}
              customRequest={async ({ onSuccess, onError, file, onProgress }) => {
                try {
                  let form = new FormData()
                  form.append("file", file)
                  setloadingSentFile(true)
                  let { payload, status } = await _mutateUpload(form)
                  if (payload?.code == 200) {
                    setloadingSentFile(false)
                    // console.log("_______payload__upload________ ::", payload)
                    onSuccess?.(payload)
                    // _sent("file", payload?.file_name)
                    return
                  }

                  setloadingSentFile(false)
                  showAlert.error(payload?.message || 'Tải file thất bại');
                  onError?.("Tải file không thành công." as any)
                } catch (error) {
                  onError?.("Tải file không thành công." as any)
                }
              }}
            >
              <Button loading={loadingSentFile} icon={<UploadOutlined />}>Upload</Button>
            </Upload>
            <div className="">
              {/* <Select
                placeholder="Chat nhanh"
                allowClear={true}
                loading={loadingSentCmt}
                style={{ marginRight: 10, width: 150 }}
                value={'Chat nhanh'}
                onChange={(value) => {
                  _sent("text", value)
                  setTimeout(() => {
                    queryListMess()
                  }, 300);
                  // console.log('value____', value);
                }}
              >
                {
                  (payloadListMess?.data?.collection || []).map(_mess => {
                    return <Select.Option
                      key={`-mess-${_mess.id}`} value={_mess.text}>{_mess.name}</Select.Option>
                  })
                }
              </Select> */}
              <Button loading={loadingSentCmt} disabled={disableButtonSent} type="primary" size="large" onClick={() => _sent("text", value)}>Gửi</Button>
            </div>
          </Space>
        </Descriptions.Item>
      </Descriptions>
    </Row>
    {/* <LoadingIndicator /> */}
  </Content>

});



