import { take, call, put, select, takeLatest } from 'redux-saga/effects';
import { chatSliceActions as actions } from '.';
import { Client } from 'app/api/Client';
import { listMessages } from 'app/api/actions/chatting';
import { Socket } from 'socket.io-client';
import { getCommentTicket } from 'app/api/actions/ticket';

function* _selectConversation(action) {
  try {
    const { payload, loading } = yield Client.query(listMessages({
      conversationId: action.payload,
    }))
    if (payload?.success) {
      yield put(actions.fetchMessageComplete(payload.data))
    }
  } catch (error) {
  }

}

function* _getListLiveChat(action) {
  try {
    const { payload, loading } = yield Client.query(getCommentTicket({
      code: action?.payload,
    }))

    console.log('payload____live-chat', payload);


    if (payload?.code == 200) {
      yield put(actions.fetchMessagesLiveChatComplete(payload?.data))
    }
  } catch (error) {
  }

}

function* _fetchListMess(action) {
  try {
    const { payload, loading } = yield Client.query(listMessages({
      page: action.payload.page,
      conversationId: action.payload.currentConversation,
    }))
    if (payload?.success) {
      yield put(actions.concatMessageComplete(payload.data))
    }
  } catch (error) {
  }

}

export function* chatSliceSaga() {
  yield takeLatest(actions.selectConversation.type, _selectConversation);
  yield takeLatest(actions.fetchListLiveChat.type, _getListLiveChat);
  yield takeLatest(actions.listMess.type, _fetchListMess);

}
