/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import 'antd/dist/reset.css';

import { GlobalStyle } from 'styles/global-styles';

import { NotFoundPage } from './components/NotFoundPage/Loadable';
import { useTranslation } from 'react-i18next';
import Login from './pages/LoginPage';
import { useDispatch, useSelector } from 'react-redux';
import { selectGlobalSlice } from './slice/selectors';
import LoadingIndicator from './components/LoadingIndicator';
import { useGlobalSliceSlice } from './slice';
import AuthenRoute from './components/AuthenRoute';
import MainLayout from './pages/MainLayout';
import io, { Socket } from "socket.io-client";
import { ResetPassword } from './pages/ResetPassword/Loadable'
let kk = 1

export function App() {
  const { i18n } = useTranslation();
  const { actions } = useGlobalSliceSlice()
  const dispatch = useDispatch()
  const { inited } = useSelector(selectGlobalSlice)



  React.useEffect(() => {
    dispatch(actions.saveToken({
      token: localStorage.getItem('accessToken'),
      email: localStorage.getItem('email'),
    }))
  }, [])


  if (!inited) {
    return <div style={{ width: '100%', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
      <LoadingIndicator />
    </div>
  }

  return (
    <BrowserRouter>
      <Helmet
        titleTemplate="%s - Tepaylink CRM"
        defaultTitle="Tepaylink CRM"
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta name="description" content="Tepaylink CRM" />
      </Helmet>

      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path={'/reset-password'} element={<ResetPassword />} />
        <Route
          path="/*"
          element={
            <AuthenRoute>
              <MainLayout />
            </AuthenRoute>
          }
        />
      </Routes>
      <GlobalStyle />
    </BrowserRouter>
  );
}
