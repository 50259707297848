import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "utils/@reduxjs/toolkit";
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import { chatSliceSaga } from "./saga";
import { ChatSliceState } from "./types";

export const initialState: ChatSliceState = {
  needReloadConversation: {},
  totalUnread: 0
};

const slice = createSlice({
  name: "chatSlice",
  initialState,
  reducers: {
    saveTotalUnread(state, action: PayloadAction<any>) {
      return {
        ...state,
        totalUnread: action.payload || 0
      }
    },
    selectPage(state, action: PayloadAction<any>) {
      let currentConversation = action.payload != state.currentPage ? undefined : state.currentConversation
      return {
        ...state,
        currentPage: action.payload,
        currentConversation
      }
    },
    selectConversation(state, action: PayloadAction<any>) {
      let messages = action.payload != state.currentConversation ? [] : state.messages
      let loadingMessage = action.payload != state.currentConversation ? true : state.loadingMessage
      return {
        ...state,
        currentConversation: action.payload,
        messages,
        loadingMessage
      }
    },
    reloadConversation(state, action: PayloadAction<any>) {
      return {
        ...state,
        needReloadConversation: {
          ...(state.needReloadConversation || {}),
          [action.payload]: Date.now()
        }
      }
    },

    fetchMessageComplete(state, action: PayloadAction<any>) {
      return {
        ...state,
        messages: action.payload,
        loadingMessage: false
      }
    },

    appendMessage(state, action: PayloadAction<any>) {
      if (state.loadingMessage) {
        return state;
      }
      let curritems = (state.messages?.items || [])
      const imgs = action.payload.filter(__ => __.fbPageId == state.currentPage && !curritems.some(_msg => _msg.id == __.id))
      // console.log('appendMessage', state.currentPage, action.payload, imgs)
      if (imgs.length > 0) {
        return {
          ...state,
          messages: {
            ...(state.messages || {}),
            items: curritems.concat(imgs)
          }
        }
      }
      return state;
    },

    markReadConversation(state, action: PayloadAction<any>) {
    }

  },
});

export const { actions: chatSliceActions } = slice;

export const useChatSliceSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: chatSliceSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useChatSliceSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
