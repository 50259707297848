import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "utils/@reduxjs/toolkit";
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import { chatSliceSaga } from "./saga";
import { ChatFBSliceState } from "./types";

export const initialState: ChatFBSliceState = {
  needReloadConversation: {},
  totalUnread: 0
};

const slice = createSlice({
  name: "chatFBSlice",
  initialState,
  reducers: {
    saveTotalUnread(state, action: PayloadAction<any>) {
      return {
        ...state,
        totalUnread: action.payload || 0
      }
    },
    selectPage(state, action: PayloadAction<any>) {
      let currentConversation = action.payload != state.currentPage ? undefined : state.currentConversation
      return {
        ...state,
        currentPage: action.payload,
        currentConversation
      }
    },
    selectConversation(state, action: PayloadAction<any>) {
      let messages = action.payload != state.currentConversation ? [] : state.messages
      let loadingMessage = action.payload != state.currentConversation ? true : state.loadingMessage
      return {
        ...state,
        currentConversation: action.payload,
        messages,
        loadingMessage
      }
    },

    listMess(state, action: PayloadAction<any>) {
      let loadingMessage = action.payload != state.currentPage ? true : state.loadingMessage
      let currentConversation = action?.payload?.currentConversation
      let curritems = (state.messages?.items || [])

      return {
        ...state,
        currentPage: action.payload.currentPage,
        currentConversation,
        loadingMessage,
      }
    },

    selectTicketCode(state, action: PayloadAction<any>) {
      let messages = action.payload != state.currentTicketCode ? [] : state.messages
      let loadingMessage = action.payload != state.currentTicketCode ? true : state.loadingMessage
      return {
        ...state,
        currentTicketCode: action.payload,
        messagesLiveChat: state.messagesLiveChat,
        loadingMessageLiveChat: state?.loadingMessageLiveChat
      }
    },

    reloadConversation(state, action: PayloadAction<any>) {
      return {
        ...state,
        needReloadConversation: {
          ...(state.needReloadConversation || {}),
          [action.payload]: Date.now()
        }
      }
    },

    fetchMessageComplete(state, action: PayloadAction<any>) {
      return {
        ...state,
        messages: action.payload,
        loadingMessage: false
      }
    },

    concatMessageComplete(state, action: PayloadAction<any>) {
      console.log('appendMessage_____state', action.payload);
      let curritems = (state.messages?.items || [])
      return {
        ...state,
        messages: {
          ...(state.messages || {}),
          items: action.payload.items.concat(curritems),
          pagination: action.payload.pagination
        },
        loadingMessage: false
      }
    },

    appendMessage(state, action: PayloadAction<any>) {
      // console.log('states__appendMessage', state)
      if (state.loadingMessage) {
        return state;
      }
      let curritems = (state.messages?.items || [])
      const imgs = action.payload.filter(__ => !curritems.some(_msg => _msg.id == __.id))
      // console.log('appendMessage', imgs, action.payload, curritems)
      if (imgs.length > 0) {
        return {
          ...state,
          messages: {
            ...(state.messages || {}),
            items: curritems.concat(imgs)
          }
        }
      }
      return state;
    },

    fetchListLiveChat(state, action: PayloadAction<any>) {

      let messagesLiveChat = action.payload != state.currentConversation ? [] : state.messagesLiveChat
      let loadingMessageLiveChat = action.payload != state.currentConversation ? true : state.loadingMessageLiveChat
      return {
        ...state,
        code: action.payload,
        messagesLiveChat: state.messagesLiveChat,
        loadingMessageLiveChat: state?.loadingMessageLiveChat
      }
    },

    fetchMessagesLiveChatComplete(state, action: PayloadAction<any>) {
      return {
        ...state,
        messagesLiveChat: action.payload,
        loadingMessageLiveChat: false
      }
    },

    appendMessageLiveChat(state, action: PayloadAction<any>) {
      if (state.loadingMessageLiveChat) {
        return state;
      }
      let curritems = (state.messagesLiveChat.collection || [])
      console.log('action___append', (action?.payload))
      if (!!action?.payload?.payload) {
        return {
          ...state,
          messagesLiveChat: {
            ...(state.messagesLiveChat || {}),
            collection: curritems?.concat([action?.payload?.payload?.comment])
          }
        }
      }
      return state;
    },
    markReadConversation(state, action: PayloadAction<any>) {
    }

  },
});

export const { actions: chatSliceActions } = slice;

export const useChatFBSliceSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: chatSliceSaga });
  return { actionsFb: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useChatSliceSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
