/**
 *
 * Asynchronously loads the component for TicketDetailPage
 *
 */

import { lazyLoad } from "utils/loadable";

export const TicketDetailPage = lazyLoad(
  () => import("./index"),
  (module) => module.TicketDetailPage
);

export const TicketDetailChat = lazyLoad(
  () => import("./components/TicketDetailChat"),
  (module) => module.TicketDetailChat
);

export const TicketDetailLogs = lazyLoad(
  () => import("./components/TicketDetailLogs"),
  (module) => module.TicketDetailLogs
);

export const TicketInternalChat = lazyLoad(() => import("./components/TicketInternalChat"),
  (module) => module.TicketInternalChat
)

export const TicketDetailRequirements = lazyLoad(
  () => import("./components/TicketDetailRequirements"),
  (module) => module.TicketDetailRequirements
);