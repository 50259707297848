/**
 *
 * TicketDetailPage
 *
 */
import { PlayCircleOutlined } from '@ant-design/icons';
import {
  Button, Descriptions,
  Empty, Input, Popconfirm, Row,
  Select, Space, Tag,
  Typography,
  message,
  Table,
  Col,
  Tabs
} from "antd";
import Card from "antd/es/card/Card";
import { Content } from "antd/es/layout/layout";
import { commentTicket, completeTicket, getCommentTicket, getTicket, receiveTicket, getActivitiesTicket, getRequirementsTicket } from "app/api/actions/ticket";
import 'react-chat-elements/dist/main.css';
import LoadingIndicator from "app/components/LoadingIndicator";
import { selectGlobalSlice } from 'app/slice/selectors';
import React, { memo, useCallback, useEffect, useMemo, useState, useRef } from "react";
import { MessageList } from "react-chat-elements";
import { useMutation, useParameterizedQuery, useQuery } from "react-fetching-library";
import { UploadOutlined } from '@ant-design/icons';

import { useSelector } from "react-redux";
import { useParams, useNavigate, Link } from "react-router-dom";
import { showAlert } from "utils/helper";

import Upload, { UploadProps } from 'antd/es/upload/Upload';
import { RcFile } from 'antd/es/upload';
import { uploadImage } from 'app/api/actions/utilities';
import TabPane from 'antd/es/tabs/TabPane';
import { IDataTabs } from 'types';
import { TICKET_STATUS_COLOR } from "../../../../constants";
import { getRequirementsHotline } from 'app/api/actions/hotline';

const { Text } = Typography;
interface Props { }
const { Option } = Select

interface TicketDetailChatProps {
  code: string;
  loading: boolean;
}

export const TicketDetailRequirements = memo((props: TicketDetailChatProps) => {

  const { query: queryRequirementsTicket, loading, payload: payloadRequirementsHotline } = useParameterizedQuery(getRequirementsHotline);
  const { roles } = useSelector(selectGlobalSlice);

  useMemo(() => {
    if (!!props?.loading) {
      queryRequirementsTicket({ code: props.code });
    }
  }, [props?.loading])


  useEffect(() => {
    queryRequirementsTicket({ code: props.code });
  }, []);

  console.log('payloadRequirementsHotline__', payloadRequirementsHotline);



  const columns = useMemo(() => {
    return [
      {
        title: 'Mã hotline',
        dataIndex: 'code',
        key: 'code',
        align: 'center',
        fixed: 'left',
        render: (__) => {
          const hasRoleDetail = !!roles && roles?.includes('ticket-show');
          if (!hasRoleDetail) {
            return <Text>{__}</Text>
          }
          return <Button onClick={() => window.open(`/hotline/${__}`, '_blank')}>{__}</Button>
        }
      },
      {
        title: 'Game',
        dataIndex: 'game',
        key: 'game',
        // fixed: 'left',
        // width: 50,
        align: 'center',
        render: (__, _record) => {
          return <Text>{_record?.game?.name}</Text>
        }
      },
      {
        title: 'Trạng thái',
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        // fixed: 'left',
        render: (__, _record) => {
          if (_record?.status == 4) {
            return <div style={{ border: '1px solid #ffd591', borderColor: '#ffd591', background: '#fff7e6', borderRadius: 4 }}>
              <span style={{ fontSize: 12, lineHeight: '20px', color: '#d46b08', }}>{_record?.status_name}</span>
            </div>
          }
          return <Tag style={{ overflow: 'hidden', textOverflow: 'ellipsis' }} color={TICKET_STATUS_COLOR[__] || "default"}>{_record?.status_name}</Tag>
        }
      },


    ]
  }, []);



  return <Table
    rowKey={'id'}
    scroll={{ x: 800 }}
    size="large"
    dataSource={payloadRequirementsHotline?.data?.collection || []}
    columns={columns as any}
    loading={loading || props?.loading}
    // columns={COLS}
    pagination={{
      position: ["bottomRight"], current: payloadRequirementsHotline?.data?.paginations?.meta?.curent_page, pageSize: 10, total: payloadRequirementsHotline?.data?.paginations?.meta?.total,
      onChange: (page, pageSize) => {
        console.log('____', page, pageSize);
        queryRequirementsTicket({ code: props?.code, page: page, perPage: 10 })
      },
      showTotal: (total) => `Tổng số: ${total} bản ghi`
    }}
    bordered
  />

});



