import { TYPE_API } from "..";
import queryString from 'querystring';

export const getGroup = ({ page, perPage, filter }: { page?: number, perPage?: number, filter?: string }): any => {
  return {
    method: 'GET',
    endpoint: `/admin/group`,
    type: TYPE_API.CRM
  }
};

export const createGroup = (payload: any): any => {
  return {
    method: 'POST',
    endpoint: `/admin/group`,
    type: TYPE_API.CRM,
    body: payload
  }
};

export const getUserGroup = ({ id }: { id?: number }): any => {
  return {
    method: 'GET',
    endpoint: `/admin/game/${id}/admin`,
    type: TYPE_API.CRM
  }
};

export const getMultiUserGroup = ({ listGame }: { listGame?: any }): any => {
  return {
    method: 'GET',
    endpoint: `/admin/group/getAdminAddGroup?${listGame}`,
    type: TYPE_API.CRM
  }
};


export const actionEditGroup = (formData: { body: any, id: any }): any => {
  return {
    method: 'PATCH',
    endpoint: `/admin/group/${formData?.id}`,
    type: TYPE_API.CRM,
    body: formData?.body
  }
}

export const deleteGroups = (id: number): any => {
  return {
    method: 'DELETE',
    endpoint: `/admin/group/${id}`,
    type: TYPE_API.CRM,
  }
}

export const getListGroup = (
  { code }: {
    code: any,
  }
): any => {
  return {
    method: 'GET',
    endpoint: `/admin/ticket/${code}/groups`,
    type: TYPE_API.CRM,
    // body: payload
  }
};

export const askForHelpGroup = (formData: { body: any, code: any }): any => {
  return {
    method: 'POST',
    endpoint: `/admin/ticket/${formData?.code}/groups/ask-for-helps`,
    type: TYPE_API.CRM,
    body: formData?.body
  }
};


export const menuGroup = (): any => {
  return {
    method: 'GET',
    endpoint: `/admin/auth/group`,
    type: TYPE_API.CRM
  }
};