/**
 *
 * Asynchronously loads the component for WikiPage
 *
 */

import {lazyLoad} from 'utils/loadable';

export const WikiPage = lazyLoad(
  () => import('./index'),
  (module) => module.WikiPage,
);
