/**
 *
 * Asynchronously loads the component for CategoryPropertiesPage
 *
 */

import { lazyLoad } from "utils/loadable";

export const CategoryPropertiesPage = lazyLoad(
  () => import("./index"),
  (module) => module.CategoryPropertiesPage
);
