import { take, call, put, select, takeLatest } from 'redux-saga/effects';
import { chatSliceActions as actions } from '.';
import { Client } from 'app/api/Client';
import { listMessages } from 'app/api/actions/chatting';
import { Socket } from 'socket.io-client';

function* _selectConversation(action) {
  try {
    const { payload, loading } = yield Client.query(listMessages({
      conversationId: action.payload
    }))

    if (payload?.success) {
      yield put(actions.fetchMessageComplete(payload.data))
    }
  } catch (error) {

  }

}

export function* chatSliceSaga() {
  yield takeLatest(actions.selectConversation.type, _selectConversation);
}
