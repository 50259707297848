import React, { memo, useEffect, useMemo, useRef } from 'react';
import { Route, Routes } from 'react-router-dom';
import Dashboard from './Dasboard';
import { HomePage } from '../HomePage/Loadable';
import { FanPage } from '../FanPage/Loadable';
import { NotFoundPage } from 'app/components/NotFoundPage/Loadable';
import { ChatPage } from '../ChatPage/Loadable';
import { CategoryPage } from '../CategoryPage/Loadable';
import { CategoryTypePage } from '../CategoryTypePage/Loadable';
import { CategoryPropertiesPage } from '../CategoryPropertiesPage/Loadable';
import { TicketPage } from '../TicketPage/Loadable';
import { TicketGroupPage } from '../TicketGroupPage/Loadable';
import { TicketDetailPage } from '../TicketDetailPage/Loadable';
import { UserManagerPage } from '../UserManagerPage/Loadable';
import { SocketProvider } from 'app/contexts/SocketContext';
import { ResetPassword } from '../ResetPassword/Loadable';
import AuthorizationPage from '../AuthorizationPage';
import AuthorizeRoute from 'app/components/AuthorizeRoute';
import { useParameterizedQuery } from 'react-fetching-library';
import { getInfo } from 'app/api/actions/authen';
import { useDispatch } from 'react-redux';
import { useGlobalSliceSlice } from 'app/slice';
import { NewsEvents } from '../NewsEvents/Loadable';
import { TagsPage } from '../TagsPage/Loadable';
import { NewsEventsPage } from '../NewsEventsPage/Loadable'
import { StatisticalPage } from '../StatisticalPage/Loadable';
import { ConversationPage } from '../ConversationPage/Loadable';
import GroupPage from '../GroupPage';
import { TicketGamePage } from '../TicketGamePage/Loadable';
import { TicketWaitingPage } from '../TicketWaitingPage/Loadable';
import { TicketGroupIdPage } from '../TicketGroupIdPage/Loadable';
import { TemplateChatPage } from '../TemplateChatPage/Loadable';
import { ReportPage } from '../ReportPage/Loadable';
import { WikiPage } from '../WikiPage/Loadable';
import { WikiInfoPage } from '../WikiInfoPage/Loadable';
import { WikiEditPage } from '../WikiEditPage/Loadable';
import { HotlinePage } from '../HotlinePage';
import { HotlineDetailPage } from '../HotlineDetailPage';

const Layout = () => {
  const dispatch = useDispatch()
  const { payload, query } = useParameterizedQuery(getInfo)
  const { actions } = useGlobalSliceSlice()

  useEffect(() => {
    query({})
  }, [])

  useMemo(() => {
    if (!payload) return
    dispatch(actions?.saveUserId(payload?.data?.id))
    dispatch(actions?.saveUserName(payload?.data?.name))
  }, [payload])

  return (
    <SocketProvider>
      <Dashboard>
        <Routes>
          <Route element={<AuthorizeRoute role='game-index' />}>
            <Route path={'/games'} element={<FanPage />} />
          </Route>
          <Route path={'/chats'} element={<ChatPage />} />
          <Route element={<AuthorizeRoute role='category-index' />}>
            <Route path={'/categories'} element={<CategoryPage />} />
          </Route>

          <Route element={<AuthorizeRoute role='ticket-show' />}>
            <Route path={'/ticket/:code'} element={<TicketDetailPage />} />
          </Route>
          <Route element={<AuthorizeRoute role='ticket-index' />}>
            <Route path={'/tickets'} element={<TicketPage />} />
          </Route>
          <Route element={<AuthorizeRoute role='ticket-index' />}>
            <Route path={'/tickets-group'} element={<TicketGroupPage />} />
          </Route>
          <Route element={<AuthorizeRoute role='ticket-index' />}>
            <Route path={'/tickets-waiting'} element={<TicketWaitingPage />} />
          </Route>
          <Route element={<AuthorizeRoute role='ticket-index' />}>
            <Route path={'/tickets-game/:code'} element={<TicketGamePage />} />
          </Route>
          <Route element={<AuthorizeRoute role='ticket-index' />}>
            <Route path={'/tickets-group-id/:code'} element={<TicketGroupIdPage />} />
          </Route>
          <Route element={<AuthorizeRoute role='tag-index' />}>
            <Route path={'/tags'} element={<TagsPage />} />
          </Route>
          <Route element={<AuthorizeRoute role='group-index' />}>
            <Route path={'/group'} element={<GroupPage />} />
          </Route>
          <Route path={'/users'} element={<UserManagerPage />} />
          <Route element={<AuthorizeRoute role='category-type-index' />}>
            <Route path={'/category-types'} element={<CategoryTypePage />} />
          </Route>
          <Route element={<AuthorizeRoute role='category-property-index' />}>
            <Route path={'/category-properties'} element={<CategoryPropertiesPage />} />
          </Route>
          <Route element={<AuthorizeRoute role='news-index' />}>
            <Route path={'/news-events'} element={<NewsEvents />} />
          </Route>
          <Route element={<AuthorizeRoute role='news-index' />}>
            <Route path={'/news-events-list'} element={<NewsEventsPage />} />
          </Route>
          <Route path={'/statistical'} element={<StatisticalPage />} />
          {/* <Route path={'/reset-password/:id'} element={<ResetPassword />} /> */}
          <Route element={<AuthorizeRoute role='conversation-index' />}>
            <Route path={'/conversation'} element={<ConversationPage />} />
          </Route>
          <Route element={<AuthorizeRoute role='chat-template-index' />}>
            <Route path={'/template'} element={<TemplateChatPage />} />
          </Route>
          {/* <Route element={<AuthorizeRoute role='ticket-index' />}>
            <Route path={'/report/:code'} element={<ReportPage />} />
          </Route> */}
          <Route element={<AuthorizeRoute role='report-token' />}>
            <Route path={'/report/:code'} element={<ReportPage />} />
          </Route>
          <Route element={<AuthorizeRoute role='report-change-status' />}>
            <Route path={'/report/:code'} element={<ReportPage />} />
          </Route>
          <Route element={<AuthorizeRoute role='report-change-status' />}>
            <Route path={'/report/:code'} element={<ReportPage />} />
          </Route>
          <Route element={<AuthorizeRoute role='wiki-index' />}>
            <Route path={'/wiki'} element={<WikiPage />} />
          </Route>
          <Route element={<AuthorizeRoute role='wiki-info-index' />}>
            <Route path={'/wiki-info'} element={<WikiInfoPage />} />
          </Route>
          <Route element={<AuthorizeRoute role='wiki-info-index' />}>
            <Route path={'/wiki-edit'} element={<WikiEditPage />} />
          </Route>
          <Route element={<AuthorizeRoute role='hotline-index' />}>
            <Route path={'/hotline'} element={<HotlinePage />} />
          </Route>
          <Route element={<AuthorizeRoute role='hotline-show' />}>
            <Route path={'/hotline/:code'} element={<HotlineDetailPage />} />
          </Route>
          <Route path={'/error-authorization'} element={<AuthorizationPage />} />
          <Route path={'/'} element={<HomePage />} />

          <Route path="*" element={<NotFoundPage />} />

        </Routes>
      </Dashboard>
    </SocketProvider>
  );
};

export default memo(Layout);
