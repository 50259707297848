/**
 *
 * HotlinePage
 *
 */
import { Button, Collapse, Input, Select, Space, Table, Tag, Typography } from 'antd';
import { askForHelpGroupHotline, changeAdminHotline, getListGroupHotline, listHotline } from 'app/api/actions/hotline';
import moment from 'moment';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation, useParameterizedQuery } from 'react-fetching-library';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ModalCreateHotline from './components/ModalCreateHotline';
import queryString from 'querystring';
import { TICKET_STATUS_COLOR } from "../../../constants";
import { getDetailGame } from 'app/api/actions/fanpage';
import { changeAdminTicket } from 'app/api/actions/ticket';
import { showAlert } from 'utils/helper';
import { useSelector } from 'react-redux';
import { selectGlobalSlice } from 'app/slice/selectors';
import { askForHelpGroup, getListGroup } from 'app/api/actions/group';

const INIT_STATE = {
  status: "",
  game_id: "",
  category_type_id: "",
  category_id: "",
  search: "",
  created_at_before: "",
  created_at_after: "",
  causer_id: ""
}
interface Props { }
const { Option } = Select

export const HotlinePage = memo((props: Props) => {
  const navigate = useNavigate()
  const location = useLocation()

  const { payload, loading, query } = useParameterizedQuery(listHotline)
  const { mutate: mutateChangeTicket } = useMutation(changeAdminHotline)
  const { mutate: mutateAskForHelpGroup, loading: loadingAskForHelpGroup, payload: payloadAskGroup } = useMutation(askForHelpGroupHotline)

  const { Text } = Typography
  const [page, setPage] = useState(1)
  const [open, setOpen] = useState(false)
  const [state, setState] = useState(INIT_STATE)

  const { roles } = useSelector(selectGlobalSlice);

  useEffect(() => {
    let obj: any = queryString.parse(location?.search.slice(1, location?.search.length))
    setPage(parseInt(obj.page || 1))
    query({
      page: parseInt(obj.page || 1)
    })
  }, [])

  const handleState = useCallback((key: "status" | "game_id" | "category_type_id" | "category_id" | "search" | "created_at_before" | "created_at_after" | "causer_id", value: string) => {
    setState((pre) => {
      return {
        ...pre,
        [key]: value
      }
    })
  }, [])


  const columns = useMemo(() => {
    return [
      {
        title: '#',
        dataIndex: 'id',
        key: 'id',
        fixed: 'left',
        width: 50,
        align: 'center',
        render: (__, _record, _idx) => _idx + 1
      },
      {
        title: 'Mã',
        dataIndex: 'code',
        key: 'code',
        fixed: 'left',
        width: 150,
        render: (__) => {
          return <>
            {!!roles?.includes('hotline-show') ?
              <Link to={`/hotline/${__}`}>{__}</Link> :
              <Text>{__}</Text>
            }
          </>
        }
      },
      {
        title: 'Người thực hiện',
        dataIndex: 'admin',
        key: 'link',
        width: 130,
        render: (__, _record) => {
          // if (_record?.status == 1) {
          //   queryGroup({ code: _record?.code })
          // }
          return <div className="">
            {!!roles?.includes('hotline-change-admin') ?
              <SelectUsers
                // idUsers={_record?.admin?.id}
                nameUsers={_record?.admin?.name}
                idGame={_record?.game?.id}
                callback={async (idUsers) => {
                  const { payload } = await mutateChangeTicket({
                    code: _record?.code,
                    id: idUsers
                  })
                  if (payload?.code == 200) {
                    query({
                      page: page
                    })
                    showAlert.success('Chuyển người gọi hotline thành công.');
                  } else {
                    showAlert.error(payload?.message || "Chuyển người gọi hotline không thành công.")
                  }

                }} /> :
              <Text>{_record?.admin?.name || ""}</Text>
            }
            {(_record?.status == 5 || _record?.status == 0) &&
              <SelectGroup
                code={_record?.code}
                callback={async (value, data) => {
                  try {
                    let checkValue = data?.filter((__: any) => value?.includes(__?.name))
                    const { payload: payloadAsk } = await mutateAskForHelpGroup({
                      code: _record?.code,
                      body: {
                        "group_id": checkValue?.[0]?.id
                      }
                    })
                    if (payloadAsk?.code == 200) {
                      query({
                        page: page,
                        "filter[search]": state.search,
                      })
                      showAlert.success("Nhờ hỗ trợ nhóm thành công")
                      return
                    }
                    query({
                      page: page,
                      "filter[search]": state.search,
                    })
                    showAlert.error(payloadAsk?.message)
                  } catch (error) {
                  }
                }}
              />
            }
          </div>
        }
      },
      {
        title: 'SĐT',
        dataIndex: 'phone',
        key: 'phone',
        width: 130,
        render: (__) => {
          return <Text>{__}</Text>
        }
      },
      {
        title: 'Trạng thái',
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        width: 130,
        render: (__, _record) => {
          return <Tag style={{ overflow: 'hidden', textOverflow: 'ellipsis' }} color={TICKET_STATUS_COLOR[__] || "default"}>{_record?.status_name}</Tag>
        }

      },
      {
        title: 'Game',
        dataIndex: 'game',
        key: 'game',
        width: 130,
        render: (__) => <Link to={`/games?id=${__?.id}`}>{__?.name}</Link>
      },
      {
        title: 'Khách hàng',
        dataIndex: 'customer',
        key: 'customer',
        width: 130,
        render: (__) => <Text>{__?.username || ""}</Text>
      },
    ]
  }, [roles, state, page]);

  return <div style={{ width: '100%', marginBottom: 8 }}>
    <div style={{ width: '100%', marginBottom: 8 }}>
      <Space style={{ display: 'flex' }} >
        <Input placeholder="Mã hotline hoặc SĐT KH" onClick={e => {
          e.stopPropagation()
        }} style={{ width: 300 }}
          // value={state.search}
          onChange={e =>
            handleState("search", e.target.value)
          }
        />
        <Button type="primary" onClick={e => {
          e.stopPropagation()
          query({
            page: page,
            "filter[search]": state.search,
          })
          // _fetchListTicket({
          //   page: 1, type
          // })
          setPage(1)
        }} >Tìm kiếm</Button>
      </Space>
      {/* <Collapse
        style={{ width: '100%', justifyContent: 'center' }}
        onChange={keys => {
        }}
        expandIconPosition={'end'}
        items={[
          {
            key: '1',
            label: <Space style={{ display: 'flex' }} >
              <Input placeholder="Mã hotline" onClick={e => {
                e.stopPropagation()
              }} style={{ width: 300 }}
              // value={state.search}
              // onChange={e => 
              //   handleState("search", e.target.value)
              // }
              />
              <Button type="primary" onClick={e => {
                e.stopPropagation()
                // _fetchListTicket({
                //   page: 1, type
                // })
                setPage(1)
              }} >Tìm kiếm</Button>
            </Space>
          }
        ]}
      /> */}
    </div>
    {!!roles?.includes('hotline-store') &&
      <Space style={{ marginBottom: 16, }}>
        <Button type="primary" onClick={e => {
          setOpen(true)
        }} >Tạo cuộc gọi hotline mới</Button>
      </Space>
    }
    <Table dataSource={payload?.data?.collection || []} columns={columns as any} loading={loading} rowKey={'id'}
      pagination={{
        position: ["bottomRight"], current: page, pageSize: 10, total: payload?.data?.paginations?.meta?.total,
        onChange: (page, pageSize) => {
          setPage(page)
          navigate("/hotline?page=" + page)
          query({
            page: page
          })
          // _fetchListTicket({ page, type })
        },
        showTotal: (total) => `Tổng số: ${total} bản ghi`
      }}
      scroll={{ x: 1300 }}
    />
    <ModalCreateHotline open={open} setOpen={(open) => {
      setOpen(open)
    }}
      refreshWhenUpdated={() => {
        query({
          page
        })
      }
      }
    />
  </div>;
});


const SelectUsers = memo(({ idGame, callback, nameUsers }: { nameUsers: string, idGame: string, callback?: (idUsers: number) => void }) => {
  const { query, payload, loading } = useParameterizedQuery(getDetailGame)
  return (
    <Select
      style={{ width: '100%' }}
      placeholder="Chọn người"
      loading={loading}
      onClick={() => {
        query({
          id: idGame, parramsQuery: {
            page: 1,
            perPage: 50,
          }
        })
      }}
      // onFocus={}
      onChange={(value: any) => {
        if (!value) return
        // setUsers(value)
        if (!!callback) callback(value)
      }}
      value={
        nameUsers
      }
    >
      {!!payload && payload?.data?.users?.map((__, index) => {
        return (
          <Option value={__?.id} label={__?.name} key={index}>
            <Space>
              {__?.name || "heloo"}
            </Space>
          </Option>
        )
      })}
    </Select>
  )
})

const SelectGroup = memo(({ code, callback }: { code: string, callback?: (value: any, data: any) => void }) => {
  const { query: queryGroup, payload: payloadGroup, loading: loadingGroup } = useParameterizedQuery(getListGroupHotline)
  const [value, setValue] = useState<any>()

  return (
    <Select
      onClear={() => {

      }}
      style={{ width: '100%', marginTop: 16 }}
      placeholder="Chọn nhóm"
      loading={loadingGroup}
      onClick={() => {
        queryGroup({
          code: code
        })
      }}
      value={value}
      // onFocus={}
      onChange={(value: any) => {
        if (!value) return
        // setUsers(value)
        if (!!callback) callback(value, payloadGroup?.data?.collection)
        setValue("")

      }}
    >
      {!!payloadGroup && payloadGroup?.data?.collection?.map((__, index) => {
        return (
          <Option value={__?.name} label={__?.name} key={index}>
            <Space>
              {__?.name}
            </Space>
          </Option>
        )
      })}
    </Select>
  )
})