import {
    Button,
    Row,
    Select,
    Spin,
    Typography
} from "antd";
import { Content } from "antd/es/layout/layout";
import { actionEditHotlineTag, listConversationHotline } from "app/api/actions/hotline";
import { actionEditTag, listConversationTags, listConversationTicket } from "app/api/actions/ticket";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import 'react-chat-elements/dist/main.css';
import { useMutation, useParameterizedQuery, useQuery } from "react-fetching-library";
import { useMatch, useNavigate } from "react-router-dom";
import { showAlert } from "utils/helper";

const { Text, Link } = Typography;
interface Props { }
const { Option } = Select

interface TicketConversationProps {
    index: number;
    title: string;
    count?: any;
    id?: number;
    code?: any;
    callback: () => void
}

export const TicketConversation = memo((props: TicketConversationProps) => {
    const [count, setCount] = useState<number>(props?.count || 0)
    const [code, setCode] = useState<any>(props?.code || "")
    const [idTags, setIdTags] = useState<any>(props?.id)

    const { mutate: mutateEdit, loading: loading } = useMutation(actionEditHotlineTag)
    const { payload: payloadConversation, loading: loadingConversation, query: queyrConversation } = useParameterizedQuery(listConversationTags)
    const { payload: payloadConverTicket, loading: loadingConverTicket, query: queryConverTicket } = useParameterizedQuery(listConversationHotline)
    const navigate = useNavigate()

    //life

    useEffect(() => {
        if (!props) return
        setCount(props?.count)
        setCode(props?.code)
        setIdTags(props?.id)
    }, [props])


    //action
    const _editAction = useCallback(async (type: string) => {
        try {
            let body = {
                "action": type === "plus" ? "add" : "sub",
            }
            let { payload } = await mutateEdit({ body: body, code: code, idTag: idTags || props?.id })
            if (payload?.code == 200) {
                showAlert.success('Cập nhật thành công.');
                queyrConversation({})
                queryConverTicket({ code: code })
                console.log('payload__', payload);
                // navigate(`/ticket/${code}`)
                setCount(payload?.data?.countable)
                if (!!props?.callback) props?.callback()
            } else {
                showAlert.error(payload?.message || 'Cập nhật thất bại.');
                throw Error(payload?.message || "Cập nhật không thành công.")
            }
            return
        } catch (error) {

        }
        // setCount(count + 1)
    }, [count, idTags, code, props?.callback])

    const _minusAction = useCallback(() => {
        if (count <= 0) return
        setCount(count - 1)
    }, [count])

    return <Content
        key={`-key-count-${props?.index}`}
        style={{
            // minHeight: 280,
            background: props?.index % 2 == 0 ? '#d0e3f5' : 'white',
            // borderRadius: 4,
            padding: 8,
            alignItems: 'center',
            justifyContent: 'center',
            // display: 'flex',
            width: '100%',
            // marginTop: 16,
        }}
    >
        <Spin spinning={loading}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', flex: 1 }}>
                <Row style={{ flex: 1 }} >
                    <Text>{props.title}</Text>
                </Row>
                <Row style={{ justifyContent: 'center', alignItems: 'center', }}>
                    {props?.count > 0 &&
                        <Button onClick={() => _editAction("subtract")}>-</Button>
                    }
                    <div className="" style={{ borderColor: '#d7d7d7', borderWidth: 1, background: '#d7d7d7', padding: 4, marginLeft: 4, marginRight: 4, minWidth: 35 }}>
                        <Text style={{ margin: 8 }}>{props?.count || count}</Text>
                    </div>
                    <Button onClick={() => _editAction("plus")}>+</Button>
                </Row>
            </div>
        </Spin>
    </Content>

});



