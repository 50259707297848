/**
 *
 * Asynchronously loads the component for WikiPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const WikiInfoPage = lazyLoad(
  () => import('./index'),
  (module) => module.WikiInfoPage,
);
