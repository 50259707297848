/**
 *
 * HotlineDetailPage
 *
 */
import { CopyOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { Button, Card, Col, Descriptions, Row, Space, Tabs, Tag, Typography } from 'antd';
import { getHotline, getInternalCommentHotline, getRequirementsHotline, listConversationHotline } from 'app/api/actions/hotline';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { useParameterizedQuery, useQuery } from 'react-fetching-library';
import { Link, useParams } from 'react-router-dom';
import { TicketInternalChat } from './components/TicketInternalChat';
import LayoutWrapper from './Layout.styles';
import { TicketDetailLogs } from './components/TicketDetailLogs';
import { getActivitiesTicket, listConversation } from 'app/api/actions/ticket';
import { useSelector } from 'react-redux';
import { selectGlobalSlice } from 'app/slice/selectors';
import ModalButton from './components/ModalButton';
import { TICKET_STATUS_COLOR, TYPE_BUTTON } from "../../../constants";
import { showAlert } from 'utils/helper';
import ModalReason from './components/ModalReason';
import { TicketDetailRequirements } from './components/TicketDetailRequirements';
import TabPane from 'antd/es/tabs/TabPane';
import { IDataTabs } from 'types';
import { TicketConversation } from './components/TicketConversation';

enum TypeProperties {
  STRING = "string",
  NUMBER = "number",
  IMAGE = "image",
  DATE_TIME = "datetime",
  FILE = "file",
  VIDEO_LIB = "video_of_lib",
  OPTION = "option",
  IMAGE_LIB = "image_of_lib",
  TEXTAREA = 'textarea',
  USERNAME = 'username',
  GAME = 'game',
  SERVER = 'server',
  ROLENAME = 'rolename',
}


function getOption(property_input_type, value) {
  try {
    if (!!property_input_type && !!JSON.parse(property_input_type)) {
      return <Text>{JSON.parse(property_input_type)[value]}</Text>
    }
    return <></>
  }
  finally {
    return <Text>{JSON.parse(property_input_type)[value]}</Text>
  }
}



interface Props { }
const { Title, Text } = Typography

export const HotlineDetailPage = memo((props: Props) => {
  const info = useSelector(selectGlobalSlice)

  const params = (useParams() || {}) as { code: string }

  const { payload, loading, query } = useQuery(getHotline({ code: params.code }))
  const { query: queryActivityTicket, payload: payloadActivitiesTicket, loading: loadingActivitiesTicket } = useParameterizedQuery(getActivitiesTicket)
  const { payload: _payload, query: _query } = useParameterizedQuery(getInternalCommentHotline)
  const { query: queryRequirementsHotline, payload: payloadRequirementsHotline, loading: loadingRequirementsHotline } = useParameterizedQuery(getRequirementsHotline)
  const { payload: payloadConversationHotline, loading: loadingConversation, query: queryConversationHotline } = useParameterizedQuery(listConversation)
  const { payload: payloadConverTicket, loading: loadingConverTicket, query: queryConverTicket } = useQuery(listConversationHotline({ code: params?.code }))


  const [open, setOpen] = useState(false)
  const [typeButton, setTypeButton] = useState<any>()
  const [modalButton, setModalButton] = useState<any>()

  const { roles } = useSelector(selectGlobalSlice);

  const _reason: any = useMemo(() => {
    if (!payload?.data?.reason_options) {
      return []
    }
    return Object.entries(payload?.data?.reason_options).map(([key, value]) => ({ key, value }));
    // return []
  }, [payload])

  const dataTabs = useMemo(() => {
    return [
      {
        key: 'tab-3',
        name: 'Lịch sử',
        component: <TicketDetailLogs loading={loadingActivitiesTicket} code={!!params && params?.code} />,
      },
      {
        key: 'tab-4',
        name: 'Yêu cầu liên quan',
        component: <TicketDetailRequirements loading={loadingRequirementsHotline} code={!!params && params?.code} />,
      },
    ];
  }, [params, loadingActivitiesTicket, loadingRequirementsHotline]);

  useEffect(() => {
    if (!!params) {
      queryRequirementsHotline({ code: params?.code });
      queryActivityTicket({ code: params?.code });
      queryConversationHotline({})
    }
  }, [])


  console.log('payload____', payload);



  return <LayoutWrapper>
    <Row gutter={16}>
      <Col xs={24} sm={12} md={12} lg={12} >
        <Card>
          {!!payload?.data?.ticket &&
            <Title className='title_ticket' style={{ fontWeight: 'normal', margin: 0, cursor: 'pointer' }}>{`Mã ticket #`}<Link to={`/ticket/${payload?.data?.ticket?.code}`}>{payload?.data?.ticket?.code || ""}</Link></Title>
          }
          <Space style={{ margin: '16px 0px' }}>
            <Title className='title_ticket' style={{ fontWeight: 'normal', margin: 0 }}>{`Mã hotline #${payload?.data?.code || ""}`}</Title>
            <Button onClick={() => {
              navigator?.clipboard?.writeText(`${payload?.data?.code}`)
              showAlert.success("Copied")
            }} icon={<CopyOutlined style={{ fontSize: 16 }} />}></Button>
            {/* <h2 style={{ marginBottom: 0 }} >{`Ticket #${payload?.data?.code}`}</h2> */}
            <Tag color={TICKET_STATUS_COLOR[payload?.data?.status] || "default"}>{payload?.data?.status_name}</Tag>
          </Space>
          {roles?.includes('hotline-receive') &&
            (payload?.data?.status == 0 || payload?.data?.status == 4 || payload?.data?.status == 6 || (payload?.data?.status == 3 && !!payload?.data?.group?.users?.find((__) => __?.id == info?.user_id))) ?
            <Button type="primary"
              onClick={() => {
                setTypeButton(TYPE_BUTTON?.receive)
                setModalButton(true)
              }}
            >
              Nhận xử lý hotline
            </Button>
            : <></>
          }
          {info?.user_id == payload?.data?.admin?.id &&
            payload?.data?.status == 10 || payload?.data?.status == 5 &&
            <Space className='action_ticket' style={{ marginBottom: 8, width: '100%' }} >
              {payload?.data?.status == 0 ?
                <></>
                :
                <Col>
                  <Row style={{ marginBottom: 8 }}>
                    {roles?.includes('hotline-cancel') &&
                      <Button style={{ marginRight: 8 }} size="large" onClick={() => setOpen(true)}>Từ chối yêu cầu</Button>
                    }
                    {roles?.includes('hotline-complete') &&
                      <Button type="primary" size="large" onClick={() => {
                        setTypeButton(TYPE_BUTTON?.confirmclose)
                        setModalButton(true)
                      }}>Đóng yêu cầu</Button>
                    }
                  </Row>
                </Col>
              }
            </Space>
          }
          <Descriptions size='small'
            title={
              <>
                {payload?.data?.admin && <>
                  <Text>Người tiếp nhận hỗ trợ - {payload?.data?.admin?.name || ""}</Text>
                  <br />
                </>
                }
                {payload?.data?.group?.users?.length > 0 && <>
                  <Text>Nhóm tiếp nhận hỗ trợ : {(payload?.data?.group?.users || [])?.map((_sub: any, index: number) => {
                    return (
                      <Text key={index}>{` ${_sub?.name}`} {payload?.data?.group?.users?.length == index + 1 ? "" : ","}</Text>
                    )
                  })}</Text>
                  <br />
                </>
                }
                {payload?.data?.category_type?.name && <>
                  <Text>{payload?.data?.category_type?.category?.name} - {payload?.data?.category_type?.name}</Text>
                  <br />
                </>
                }
                <Text>{payload?.data?.game?.name}</Text>
                <br />
                <Text>Khách hàng: {payload?.data?.customer?.username}</Text>
                <br />
                <Text>SĐT: {payload?.data?.phone}</Text>
              </>
            } bordered
            style={{ width: '100%' }}
            column={2}
          >
          </Descriptions >
        </Card>
        {roles?.includes('hotline-comment-internal-index') &&
          <Card style={{ marginTop: 16 }}>
            <TicketInternalChat
              id='3' code={!!params && params?.code} />
          </Card>
        }
      </Col>
      <Col xs={24} sm={12} md={12} lg={12} >
        <Card>
          <Tabs type="card" className="tab-base">
            {dataTabs?.map((_tab: IDataTabs) => (
              <TabPane key={_tab.key} tab={_tab.name} >
                {_tab.component}
              </TabPane>
            ))}
          </Tabs>
        </Card>
        {payloadConversationHotline?.data?.collection?.length > 0 &&
          <Col xs={24}>
            <Card title="Phân loại hội thoại" style={{ marginTop: 16, marginBottom: 16 }}>
              {!!payloadConversationHotline && payloadConversationHotline?.data?.collection?.map((__item: any, index: number) => {
                let countTags = payloadConverTicket?.data?.collection?.find((_ticket) => _ticket?.id == __item?.id) || 0

                // console.log('conver____', payloadConverTicket?.data, payloadConversationHotline?.data);
                // console.log('countTags____', countTags);


                return (
                  <TicketConversation key={`-conversation-${index}`} callback={() => {
                    queryActivityTicket({ code: params.code });
                    queryRequirementsHotline({ code: params?.code });
                    query()
                    queryConverTicket()
                  }} code={params?.code} title={__item?.name} index={index} count={!!countTags ? countTags?.countable : 0} id={__item?.id} />
                )
              })}
            </Card>
          </Col>
        }
      </Col>
    </Row>
    <ModalReason open={open} setOpen={setOpen} callback={() => {
      queryActivityTicket({ code: params.code });
      queryRequirementsHotline({ code: params?.code });
      query()
    }} data={_reason} payload={payload?.data} />
    <ModalButton open={modalButton} setOpen={setModalButton} callback={() => {
      query()
      queryActivityTicket({ code: params.code });
      queryRequirementsHotline({ code: params?.code });
      if (typeButton == TYPE_BUTTON?.confirmclose) {
        // _sendMessRemaining(lastMess)
        // sendLastMess(true)
      }
    }} type={typeButton} code={params?.code} />
  </LayoutWrapper>
});
