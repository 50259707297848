import { Button, Result } from "antd";
import React, { memo } from "react";
import { useNavigate } from 'react-router-dom';

const AuthorizationPage = () => {
    const navigate = useNavigate();

    return (
        <Result
            status="403"
            title="403"
            subTitle="Xin lỗi. Bạn không có quyền truy cập trang này"
            extra={<Button type="primary" onClick={() => {
                navigate(-1)
            }}>Quay trở lại</Button>}
        />
    )
};

export default memo(AuthorizationPage);