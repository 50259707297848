/**
 *
 * Asynchronously loads the component for CategoryTypePage
 *
 */

import { lazyLoad } from "utils/loadable";

export const CategoryTypePage = lazyLoad(
  () => import("./index"),
  (module) => module.CategoryTypePage
);
