import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`

@font-face {
  font-family: 'Roboto';
}

  html,
  body {
    height: 100%;
    width: 100%;
  }
  
  * {
    font-family: 'Roboto', sans-serif !important;
  }

  body {
    font-family: 'Roboto', sans-serif !important;
  }

  #root {
    min-height: 100%;
    min-width: 100%;
  }

  p,
  label {
    font-family: 'Roboto', sans-serif !important;
    line-height: 1.5em;
  }

  input, select {
    font-family: inherit;
    font-size: inherit;
  }

  .anticon {
    vertical-align: 0px !important;
  }

  
  .editorClassName {
    border: 1px solid #f1f1f1;
    height: 400px;
    border-radius: 2px;
    padding: 12px;

    .public-DraftStyleDefault-ul {
      margin: 0 !important;
    }

    .public-DraftStyleDefault-block {
      margin: 0 !important;
    }

    .public-DraftStyleDefault-ol {
      margin: 0 !important;
    }
  }

  .ant-image-mask-info .anticon {
    position: relative;
    top: -3px;    
  }

  .ant-input-number-input-wrap input {
    height: 38px;
  }

  .input-item .ant-select-selector {
    width: 100%;
    border-radius: 4px;
    height: 40px;        
  }
  
  .base-tab .ant-tabs-tab {
    padding: 12px !important;
  }

  .space-base {
    width: 100%;
  }

  .ant-pagination-options {
    display: none !important;
  }

  .ant-pagination-item-link .anticon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .wpnp-notification {
    padding: 15px !important;
    max-height: unset !important;
  }

  .wonderpush-notification-preview {
    min-height: unset !important;
  }

  .wonderpush-notification-preview {
    align-items: start !important;
  }

  .wonderpush-notification-preview .wpnp-ios {
    border-color: #000 !important;
  }

  .wonderpush-notification-preview .wpnp-android {
    border-color: #000 !important;
  }

  .wonderpush-notification-preview .wpnp-android .wpnp-volume {
    background-color: #000 !important;
  }

  .wonderpush-notification-preview .wpnp-android .wpnp-lock {
    background-color: #000 !important;
  }
  
  .wonderpush-notification-preview .wpnp-android .wpnp-screen .wpnp-current-date {
    color: gray !important;
  }
  .wonderpush-notification-preview .wpnp-android .wpnp-screen .wpnp-current-time {
    color: gray !important;
  }
  .wonderpush-notification-preview .wpnp-ios .wpnp-screen .wpnp-close-notification .wpnp-current-time {
    color: gray !important;
  }
  .wonderpush-notification-preview .wpnp-ios .wpnp-screen .wpnp-close-notification .wpnp-current-date {
    color: gray !important;
  }

  .wonderpush-notification-preview .wpnp-ios .wpnp-button {
    background-color: #000 !important;
  }

  .wonderpush-notification-preview .wpnp-android .wpnp-button {
    background-color: #000 !important;
  }

  .wonderpush-notification-preview .wpnp-android .wpnp-screen .wpnp-current-time {
    margin-top: 30px !important;
    font-size: 50px !important;
  }

  .wonderpush-notification-preview .wpnp-ios .wpnp-screen .wpnp-close-notification .wpnp-current-time {
    margin-top: 30px !important;
    font-size: 50px !important;
  }


  .editor-wrapper {
    border: 1px solid #f1f1f1;
    min-height: 200px;
    max-height: 400px;
    border-radius: 2px;
    padding: 12px;
  }

  .toolbar-wrapper {
    display: none !important;
  }

  .rdw-mention-link {
    color: '#f77f00' !important;
  }

  .ant-table-scroll-horizontal {
    color: red !important; 
  }

  .ant-modal-content {
    border-radius: 8px !important;
  }

  .ant-modal-content {
    border-radius: 8px !important;
  }

  .ant-modal-header {
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
  }
`;
