/**
 *
 * Asynchronously loads the component for TicketPage
 *
 */

import { lazyLoad } from "utils/loadable";

export const ConversationPage = lazyLoad(
  () => import("./index"),
  (module) => module.default
);
