/**
 *
 * Asynchronously loads the component for WikiEditPage
 *
 */

import {lazyLoad} from 'utils/loadable';

export const WikiEditPage = lazyLoad(
  () => import('./index'),
  (module) => module.WikiEditPage,
);
